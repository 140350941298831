import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  isValidDateStr,
  notEmptyValidator,
} from "../../utils/FormManager";
import { MyDateInput } from "../common/MyDateInput";
import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";

const name2validators = {
  room_code: [notEmptyValidator()],
  checkin_date: [isValidDateStr()],
  checkout_date: [isValidDateStr()],
  email: [notEmptyValidator()],
};

const name2validatorsCode = {
  email: [notEmptyValidator()],
  code: [notEmptyValidator()],
};

interface IReservationFindProps {}
export const ReservationFind: React.FC<IReservationFindProps> = () => {
  const {
    requestManager,
    user,
    setUser,
    getText,
    reservation,
    setReservation,
    reservationApplication,
    setReservationApplication,
  } = useContext(AppContext);

  const [room_code, setRoom_code] = useState(""); // TODO:
  const [checkin_date, setCheckin_date] = useState("");
  const [checkout_date, setCheckout_date] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});
  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // NOTE: clear existing reservation
    if (reservation) {
      setReservation(null);
    }
    if (reservationApplication) {
      setReservationApplication(null);
    }
  }, []);

  const composeReservationData = () => {
    const data = {
      room_code: room_code.trim(),
      checkin_date,
      checkout_date,
      // number,
      email: email.trim(),
    };
    const invalidFields = validateForm(data, name2validators);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: { [key: string]: string[] }, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages(msgs);
      // console.log(msgs);
      return null;
    }
    setFormMessages({});
    return data;
  };

  const composeCodeData = () => {
    const data = {
      email: email.trim(),
      code: code.trim(),
    };
    const invalidFields = validateForm(data, name2validatorsCode);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: { [key: string]: string[] }, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages({ ...formMessages, ...msgs });
      return null;
    }
    return data;
  };

  const handleValidate = async () => {
    setLoading(true);
    if (user) {
      setUser(null);
    }
    const data = composeReservationData();
    if (!data) {
      // setModalText(getText("m_res_not_found"));
      setLoading(false);
      return;
    }
    const data_reservation = {
      roat_number: data.room_code,
    };
    const resp_reservation = await requestManager.postJson(
      API_URLS.TOURIST_V2_RESERVATIONS_GET,
      data_reservation,
      false
    );
    if (resp_reservation.status === 200) {
      setReservation(resp_reservation.data);
    } else if (resp_reservation.status === 404) {
      setLoading(false);
      // alert("reservation not found");
      setModalText(getText("m_res_not_found"));
      return;
    } else {
      setLoading(false);
      // alert("error");
      setModalText(getText("m_res_error"));
      return;
    }

    const data_send_code = {
      email: data.email,
    };
    const resp_send_code = await requestManager.postJson(
      API_URLS.PASSWORD_GENERATE,
      data_send_code,
      false
    );
    if (resp_send_code.status === 200) {
      setModalText(getText("m_code_sent"));
    } else {
      setLoading(false);
      // alert("Error on sending code");
      setModalText(getText("m_code_send_failed"));
      return;
    }

    setLoading(false);
  };

  const handleCheckin = async () => {
    if (!reservation) {
      // alert("reservation not determined");
      setModalText(getText("m_validate_res"));
      return;
    }
    setLoading(true);
    const dataReservation = composeReservationData();
    const dataCode = composeCodeData();
    if (!dataReservation || !dataCode) {
      setLoading(false);
      return;
    }

    const res = await requestManager.postJson(
      API_URLS.PASSWORD_VERIFY,
      dataCode,
      false
    );
    if (res.status !== 200) {
      setLoading(false);
      setModalText(getText("m_code_invalid"));
      return;
    }
    const loginRes = await requestManager.login(dataCode.email, dataCode.code);
    if (loginRes.status !== 200) {
      setLoading(false);
      setModalText(getText("m_failed_authorize"));
      return;
    }
    const data_reservation_application = {
      room_code: dataReservation.room_code,
      checkin_date: dataReservation.checkin_date,
      checkout_date: dataReservation.checkout_date,
    };
    const resp_reservation_application = await requestManager.postJson(
      API_URLS.TOURIST_V2_RESERVATION_APPLICATIONS_FIND_OR_CREATE,
      data_reservation_application
    );
    if (resp_reservation_application.status === 200) {
      setReservationApplication(resp_reservation_application.data);
    } else {
      setLoading(false);
      setModalText(getText("m_res_app_error"));
      return;
    }

    navigate(WEB_ROUTERS.TOURIST_GUESTS_STEPS);
    setLoading(false);
  };

  return (
    <div className="page-content">
      <h3 className="header-tag">{getText("h_tourist_reservation")}</h3>
      <div style={{ display: "flex", gap: "40px", flexWrap: "wrap" }}>
        <div className="form-container w520_ifw">
          <div className="form-card">
            <div className="control__wrap">
              <div className="form__control">
                <MyInput
                  type="text"
                  label={getText("l_apartment_code")}
                  placeholder={getText("ph_apartment_code")}
                  value={room_code}
                  setValue={(v) => setRoom_code(v)}
                  validators={name2validators["room_code"]}
                  messages={formMessages["room_code"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, room_code: v })
                  }
                />
              </div>

              <div className="form__control">
                <MyDateInput
                  label={getText("l_checkin")}
                  value={checkin_date}
                  setValue={(v) => {
                    setCheckin_date(v);
                  }}
                  validators={name2validators["checkin_date"]}
                  messages={formMessages["checkin_date"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, checkin_date: v })
                  }
                />
              </div>

              <div className="form__control">
                <MyDateInput
                  label={getText("l_checkout")}
                  value={checkout_date}
                  setValue={(v) => setCheckout_date(v)}
                  validators={name2validators["checkout_date"]}
                  messages={formMessages["checkout_date"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, checkout_date: v })
                  }
                />
              </div>

              <div className="form__control">
                <MyInput
                  type="email"
                  label={getText("l_email")}
                  placeholder={getText("ph_email")}
                  value={email}
                  setValue={(v) => setEmail(v)}
                  validators={name2validators["email"]}
                  messages={formMessages["email"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, email: v })
                  }
                />
              </div>
            </div>
            <div className="control_center">
              <div className="btn bg_primary " onClick={() => handleValidate()}>
                {getText("btn_validate")}
              </div>
            </div>
          </div>

          <div className="form-card">
            <div className="control__wrap" style={{ alignItems: "center" }}>
              <div className="form__control">
                <MyInput
                  type="text"
                  label={getText("l_code")}
                  placeholder={getText("ph_code")}
                  value={code}
                  setValue={(v) => setCode(v)}
                  validators={name2validatorsCode["code"]}
                  messages={formMessages["code"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, code: v })
                  }
                />
              </div>
              <div className="form__control">{getText("msg_code_sent")}</div>
            </div>
            <div className="control_center">
              <div className="btn bg_primary" onClick={() => handleCheckin()}>
                {getText("btn_start_checkin")}
              </div>
            </div>
          </div>
        </div>
        <div className="form-container">
          <div className="form-card">
            <h3 className="header">{getText("h_watch_checkin")}</h3>
            <div className="video-container video-container_medium">
              <iframe
                src="https://www.youtube.com/embed/mgY6Klox8Oc?si=0tcAHyGDcyrhzAS4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div
            className="header-tag"
            style={{ maxWidth: "472px", fontSize: "16px" }}
          >
            {getText("t_tourists_note")}
          </div>
        </div>
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
};
