export const DEFAULT_OPTIONS: { language: string } = {
  language: "english",
};

export const THE_CODE_LINES = ["Univo S.L.","NRT L-712387-B","RC 927076W"];

export const COUNTRIES: [string, string, string][] = [
  ["AF", "Afganistan", "Afghanistan"],
  ["AL", "Alb\u00e0nia", "Albania"],
  ["DZ", "Alg\u00e8ria", "Algeria"],
  ["AS", "Samoa Nord-americana; Samoa Americana", "American Samoa"],
  ["AD", "Andorra", "Andorra"],
  ["AO", "Angola", "Angola"],
  ["AI", "Anguilla", "Anguilla"],
  ["AQ", "Ant\u00e0rtida", "Antarctica"],
  ["AG", "Antigua i Barbuda", "Antigua and Barbuda"],
  ["AR", "Argentina", "Argentina"],
  ["AM", "Arm\u00e8nia", "Armenia"],
  ["AW", "Aruba", "Aruba"],
  ["AU", "Austr\u00e0lia", "Australia"],
  ["AT", "\u00c0ustria", "Austria"],
  ["AZ", "Azerbaidjan; Azerbaidjan", "Azerbaijan"],
  ["BS", "Bahames", "Bahamas"],
  ["BH", "Bahrain", "Bahrain"],
  ["BD", "Bangladesh", "Bangladesh"],
  ["BB", "Barbados", "Barbados"],
  ["BY", "Bielor\u00fassia", "Belarus"],
  ["BE", "B\u00e8lgica", "Belgium"],
  ["BZ", "Belize", "Belize"],
  ["BJ", "Ben\u00edn", "Benin"],
  ["BM", "Bermudes; Bermuda; Bermudes, les", "Bermuda"],
  ["BT", "Bhutan", "Bhutan"],
  ["BO", "Bol\u00edvia", "Bolivia (Plurinational State of)"],
  [
    "BQ",
    "Bonaire, Sint Eustatius i Saba; Carib Neerland\u00e8s; Illes BES",
    "Bonaire, Sint Eustatius and Saba",
  ],
  ["BA", "B\u00f2snia i Hercegovina", "Bosnia and Herzegovina"],
  ["BW", "Botswana", "Botswana"],
  ["BV", "Bouvet; Bouvet, illa", "Bouvet Island"],
  ["BR", "Brasil", "Brazil"],
  [
    "IO",
    "Territori Brit\u00e0nic de l'Oce\u00e0 \u00cdndic",
    "British Indian Ocean Territory",
  ],
  ["BN", "Brunei", "Brunei Darussalam"],
  ["BG", "Bulg\u00e0ria", "Bulgaria"],
  ["BF", "Burkina Faso", "Burkina Faso"],
  ["BI", "Burundi", "Burundi"],
  ["CV", "Cap Verd", "Cabo Verde"],
  ["KH", "Cambodja; Cambotja", "Cambodia"],
  ["CM", "Camerun", "Cameroon"],
  ["CA", "Canad\u00e0", "Canada"],
  ["KY", "Caiman, illes; Caiman, les", "Cayman Islands"],
  [
    "CF",
    "Centreafricana, Rep\u00fablica; Centrafricana, Rep\u00fablica",
    "Central African Republic",
  ],
  ["TD", "Txad", "Chad"],
  ["CL", "Xile", "Chile"],
  ["CN", "Xina, Rep\u00fablica Popular de la", "China"],
  ["CX", "Christmas, illa", "Christmas Island"],
  ["CC", "Cocos, illes; Cocos (Keeling), illes", "Cocos (Keeling) Islands"],
  ["CO", "Col\u00f2mbia", "Colombia"],
  ["KM", "Comores", "Comoros"],
  ["CG", "Congo, Rep\u00fablica del", "Congo"],
  [
    "CD",
    "Congo, Rep\u00fablica Democr\u00e0tica del",
    "Congo, Democratic Republic of the",
  ],
  ["CK", "Cook, illes", "Cook Islands"],
  ["CR", "Costa Rica", "Costa Rica"],
  ["HR", "Cro\u00e0cia", "Croatia"],
  ["CU", "Cuba", "Cuba"],
  ["CW", "Cura\u00e7ao", "Cura\u00e7ao"],
  ["CY", "Xipre", "Cyprus"],
  ["CZ", "Tx\u00e8quia", "Czechia"],
  ["CI", "Costa d'Ivori", "C\u00f4te d'Ivoire"],
  ["DK", "Dinamarca", "Denmark"],
  ["DJ", "Djibouti", "Djibouti"],
  ["DM", "Dominica", "Dominica"],
  ["DO", "Dominicana, Rep\u00fablica", "Dominican Republic"],
  ["EC", "Equador", "Ecuador"],
  ["EG", "Egipte", "Egypt"],
  ["SV", "Salvador, El; Salvador, el", "El Salvador"],
  ["GQ", "Guinea Equatorial", "Equatorial Guinea"],
  ["ER", "Eritrea", "Eritrea"],
  ["EE", "Est\u00f2nia", "Estonia"],
  ["SZ", "Swazil\u00e0ndia", "Eswatini"],
  ["ET", "Eti\u00f2pia", "Ethiopia"],
  [
    "FK",
    "Malvines, illes; Malvines (Falkland), illes",
    "Falkland Islands (Malvinas)",
  ],
  ["FO", "F\u00e8roe, illes", "Faroe Islands"],
  ["FJ", "Fiji", "Fiji"],
  ["FI", "Finl\u00e0ndia", "Finland"],
  ["FR", "Fran\u00e7a", "France"],
  ["GF", "Guaiana Francesa; Guaiana Francesa, la", "French Guiana"],
  ["PF", "Polin\u00e8sia Francesa", "French Polynesia"],
  [
    "TF",
    "Territoris Francesos del Sud; Terres Australs Franceses; Fran\u00e7a (territoris del sud)",
    "French Southern Territories",
  ],
  ["GA", "Gabon", "Gabon"],
  ["GM", "G\u00e0mbia", "Gambia"],
  ["GE", "Ge\u00f2rgia", "Georgia"],
  ["DE", "Alemanya", "Germany"],
  ["GH", "Ghana", "Ghana"],
  ["GI", "Gibraltar", "Gibraltar"],
  ["GR", "Gr\u00e8cia", "Greece"],
  ["GL", "Groenl\u00e0ndia", "Greenland"],
  ["GD", "Grenada", "Grenada"],
  ["GP", "Guadeloupe; Guadalupe", "Guadeloupe"],
  ["GU", "Guam", "Guam"],
  ["GT", "Guatemala", "Guatemala"],
  ["GG", "Guernsey", "Guernsey"],
  ["GN", "Rep\u00fablica de Guinea", "Guinea"],
  ["GW", "Guinea Bissau; Guinea-Bissau", "Guinea-Bissau"],
  ["GY", "Guyana", "Guyana"],
  ["HT", "Hait\u00ed", "Haiti"],
  ["HM", "Heard, illa i McDonald, illes", "Heard Island and McDonald Islands"],
  ["VA", "Vatic\u00e0, Ciutat del", "Holy See"],
  ["HN", "Hondures", "Honduras"],
  ["HK", "Hong Kong", "Hong Kong"],
  ["HU", "Hongria", "Hungary"],
  ["IS", "Isl\u00e0ndia", "Iceland"],
  ["IN", "\u00cdndia", "India"],
  ["ID", "Indon\u00e8sia", "Indonesia"],
  ["IR", "Iran", "Iran (Islamic Republic of)"],
  ["IQ", "Iraq", "Iraq"],
  ["IE", "Irlanda", "Ireland"],
  ["IM", "Illa de Man", "Isle of Man"],
  ["IL", "Israel", "Israel"],
  ["IT", "It\u00e0lia", "Italy"],
  ["JM", "Jamaica", "Jamaica"],
  ["JP", "Jap\u00f3", "Japan"],
  ["JE", "Jersey", "Jersey"],
  ["JO", "Jord\u00e0nia", "Jordan"],
  ["KZ", "Kazakhstan", "Kazakhstan"],
  ["KE", "Kenya", "Kenya"],
  ["KI", "Kiribati", "Kiribati"],
  [
    "KP",
    "Corea del Nord; Corea, Rep\u00fablica Democr\u00e0tica Popular de",
    "Korea (Democratic People's Republic of)",
  ],
  ["KR", "Corea del Sud; Corea, Rep\u00fablica de", "Korea, Republic of"],
  ["KW", "Kuwait", "Kuwait"],
  ["KG", "Kirguizstan", "Kyrgyzstan"],
  ["LA", "Laos", "Lao People's Democratic Republic"],
  ["LV", "Let\u00f2nia", "Latvia"],
  ["LB", "L\u00edban", "Lebanon"],
  ["LS", "Lesotho", "Lesotho"],
  ["LR", "Lib\u00e8ria", "Liberia"],
  ["LY", "L\u00edbia", "Libya"],
  ["LI", "Liechtenstein", "Liechtenstein"],
  ["LT", "Litu\u00e0nia", "Lithuania"],
  ["LU", "Luxemburg", "Luxembourg"],
  ["MO", "Macau", "Macao"],
  ["MG", "Madagascar", "Madagascar"],
  ["MW", "Malawi", "Malawi"],
  ["MY", "Mal\u00e0isia", "Malaysia"],
  ["MV", "Maldives", "Maldives"],
  ["ML", "Mali", "Mali"],
  ["MT", "Malta", "Malta"],
  ["MH", "Marshall, illes; Marshall", "Marshall Islands"],
  ["MQ", "Martinica", "Martinique"],
  ["MR", "Maurit\u00e0nia", "Mauritania"],
  ["MU", "Maurici", "Mauritius"],
  ["YT", "Mayotte", "Mayotte"],
  ["MX", "M\u00e8xic", "Mexico"],
  [
    "FM",
    "Micron\u00e8sia, Estats Federats de",
    "Micronesia (Federated States of)",
  ],
  ["MD", "Mold\u00e0via, Rep\u00fablica de", "Moldova, Republic of"],
  ["MC", "M\u00f2naco", "Monaco"],
  ["MN", "Mong\u00f2lia", "Mongolia"],
  ["ME", "Montenegro", "Montenegro"],
  ["MS", "Montserrat", "Montserrat"],
  ["MA", "Marroc", "Morocco"],
  ["MZ", "Mo\u00e7ambic", "Mozambique"],
  ["MM", "Myanmar; Myanma", "Myanmar"],
  ["NA", "Nam\u00edbia", "Namibia"],
  ["NR", "Nauru", "Nauru"],
  ["NP", "Nepal", "Nepal"],
  ["NL", "Pa\u00efsos Baixos", "Netherlands"],
  ["NC", "Nova Caled\u00f2nia", "New Caledonia"],
  ["NZ", "Nova Zelanda", "New Zealand"],
  ["NI", "Nicaragua", "Nicaragua"],
  ["NE", "N\u00edger", "Niger"],
  ["NG", "Nig\u00e8ria", "Nigeria"],
  ["NU", "Niue", "Niue"],
  ["NF", "Norfolk, illa; Norfolk", "Norfolk Island"],
  ["MK", "Maced\u00f2nia del Nord", "North Macedonia"],
  [
    "MP",
    "Mariannes Septentrionals, illes; Mariannes del Nord, illes; Marianes del Nord, illes",
    "Northern Mariana Islands",
  ],
  ["NO", "Noruega", "Norway"],
  ["OM", "Oman", "Oman"],
  ["PK", "Pakistan", "Pakistan"],
  ["PW", "Palau", "Palau"],
  ["PS", "Palestina; Cisjord\u00e0nia i Gaza", "Palestine, State of"],
  ["PA", "Panam\u00e0", "Panama"],
  ["PG", "Papua Nova Guinea", "Papua New Guinea"],
  ["PY", "Paraguai", "Paraguay"],
  ["PE", "Per\u00fa", "Peru"],
  ["PH", "Filipines", "Philippines"],
  ["PN", "Pitcairn, illes; Pitcairn", "Pitcairn"],
  ["PL", "Pol\u00f2nia", "Poland"],
  ["PT", "Portugal", "Portugal"],
  ["PR", "Puerto Rico", "Puerto Rico"],
  ["QA", "Qatar", "Qatar"],
  ["RO", "Romania", "Romania"],
  ["RU", "R\u00fassia", "Russian Federation"],
  ["RW", "Ruanda", "Rwanda"],
  [
    "RE",
    "Reuni\u00f3, illa de la; Reuni\u00f3, la; Reunion; R\u00e9union",
    "R\u00e9union",
  ],
  [
    "BL",
    "Saint-Barth\u00e9lemy; Saint-Barth\u00e9lemy (Antilles)",
    "Saint Barth\u00e9lemy",
  ],
  [
    "SH",
    "Santa Helena; Saint Helena",
    "Saint Helena, Ascension and Tristan da Cunha",
  ],
  [
    "KN",
    "Saint Kitts i Nevis; Saint Christopher i Nevis",
    "Saint Kitts and Nevis",
  ],
  ["LC", "Saint Lucia", "Saint Lucia"],
  [
    "MF",
    "Saint-Martin; Saint-Martin (Antilles Franceses)",
    "Saint Martin (French part)",
  ],
  [
    "PM",
    "Saint-Pierre i Miquelon; Saint Pierre i Miquelon; Saint-Pierre-et-Miquelon",
    "Saint Pierre and Miquelon",
  ],
  [
    "VC",
    "Saint Vincent i les Grenadines; Saint Vincent i Grenadines",
    "Saint Vincent and the Grenadines",
  ],
  ["WS", "Samoa", "Samoa"],
  ["SM", "San Marino", "San Marino"],
  [
    "ST",
    "S\u00e3o Tom\u00e9 i Pr\u00edncipe; Sao Tom\u00e9 i Pr\u00edncipe",
    "Sao Tome and Principe",
  ],
  ["SA", "Ar\u00e0bia Saudita", "Saudi Arabia"],
  ["SN", "Senegal", "Senegal"],
  ["RS", "S\u00e8rbia", "Serbia"],
  ["SC", "Seychelles", "Seychelles"],
  ["SL", "Sierra Leone", "Sierra Leone"],
  ["SG", "Singapur", "Singapore"],
  ["SX", "Sint Maarten", "Sint Maarten (Dutch part)"],
  ["SK", "Eslov\u00e0quia", "Slovakia"],
  ["SI", "Eslov\u00e8nia", "Slovenia"],
  ["SB", "Salom\u00f3", "Solomon Islands"],
  ["SO", "Som\u00e0lia", "Somalia"],
  ["ZA", "Sud-\u00e0frica; Sud-\u00e0frica, Rep\u00fablica de", "South Africa"],
  [
    "GS",
    "Ge\u00f2rgia del Sud i Sandwich del Sud, illes",
    "South Georgia and the South Sandwich Islands",
  ],
  ["SS", "Sudan del Sud; Rep\u00fablica del Sudan del Sud", "South Sudan"],
  ["ES", "Espanya", "Spain"],
  ["LK", "Sri Lanka", "Sri Lanka"],
  ["SD", "Sudan", "Sudan"],
  ["SR", "Surinam", "Suriname"],
  ["SJ", "Svalbard i Jan Mayen", "Svalbard and Jan Mayen"],
  ["SE", "Su\u00e8cia", "Sweden"],
  ["CH", "Su\u00efssa", "Switzerland"],
  ["SY", "S\u00edria", "Syrian Arab Republic"],
  ["TW", "Taiwan", "Taiwan, Province of China"],
  ["TJ", "Tadjikistan", "Tajikistan"],
  ["TZ", "Tanz\u00e0nia", "Tanzania, United Republic of"],
  ["TH", "Tail\u00e0ndia", "Thailand"],
  ["TL", "Timor Oriental; Timor-Leste", "Timor-Leste"],
  ["TG", "Togo", "Togo"],
  ["TK", "Tokelau", "Tokelau"],
  ["TO", "Tonga", "Tonga"],
  ["TT", "Trinitat i Tobago", "Trinidad and Tobago"],
  ["TN", "Tun\u00edsia", "Tunisia"],
  ["TR", "Turquia", "Turkey"],
  ["TM", "Turkmenistan", "Turkmenistan"],
  ["TC", "Turks i Caicos, illes", "Turks and Caicos Islands"],
  ["TV", "Tuvalu", "Tuvalu"],
  ["UG", "Uganda", "Uganda"],
  ["UA", "Ucra\u00efna", "Ukraine"],
  [
    "AE",
    "Emirats \u00c0rabs Units; Uni\u00f3 dels Emirats \u00c0rabs",
    "United Arab Emirates",
  ],
  [
    "GB",
    "Regne Unit; Gran Bretanya",
    "United Kingdom of Great Britain and Northern Ireland",
  ],
  [
    "UM",
    "Illes d'Ultramar Menors dels Estats Units; Estats Units d'Am\u00e8rica (illes menors allunyades)",
    "United States Minor Outlying Islands",
  ],
  [
    "US",
    "Estats Units\u00a0(EUA); Estats Units d'Am\u00e8rica",
    "United States of America",
  ],
  ["UY", "Uruguai", "Uruguay"],
  ["UZ", "Uzbekistan", "Uzbekistan"],
  ["VU", "Vanuatu", "Vanuatu"],
  ["VE", "Vene\u00e7uela", "Venezuela (Bolivarian Republic of)"],
  ["VN", "Vietnam", "Viet Nam"],
  [
    "VG",
    "Verges Brit\u00e0niques, illes; Verges, illes (Regne Unit)",
    "Virgin Islands (British)",
  ],
  [
    "VI",
    "Verges Nord-americanes, illes; Verges Americanes, illes; Verges, illes (Estats Units d'Am\u00e8rica)",
    "Virgin Islands (U.S.)",
  ],
  ["WF", "Wallis i Futuna", "Wallis and Futuna"],
  ["EH", "S\u00e0hara Occidental", "Western Sahara"],
  ["YE", "Iemen", "Yemen"],
  ["ZM", "Z\u00e0mbia", "Zambia"],
  ["ZW", "Zimb\u00e0bue", "Zimbabwe"],
  ["AX", "\u00c5land, illes; Aland, illes", "\u00c5land Islands"],
];

export const ALPHA2_TO_IDX = COUNTRIES.reduce(
  (
    map: { [key: string]: number },
    arr: [string, string, string],
    i: number
  ) => {
    map[arr[0]] = i;
    return map;
  },
  {}
);
export const COUNTRY_TO_IDX = COUNTRIES.reduce(
  (
    map: { [key: string]: number },
    arr: [string, string, string],
    i: number
  ) => {
    map[arr[2]] = i;
    return map;
  },
  {}
);
export const COUNTRY_ENGLISH = COUNTRIES.map((m) => m[2]);
