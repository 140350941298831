import {createContext} from "react";
import { IUser } from "../components/common/types";


export interface IAppContext {
    requestManager: any;
    user: IUser | null;
    setUser: (x:any) => void;
    reservation: any;
    setReservation: (x:any) => void;
    reservationApplication: any;
    setReservationApplication: (x:any) => void;
    accomodationId: any;
    setAccomodationId: (x:any) => void;
    language: string;
    setLanguage: (lang: string) => void;
    getText: (key: string) => string;
}

const defaultContext = {
    requestManager: null,
    user: null,
    setUser: () => {},
    reservation: null,
    setReservation: () => {},
    reservationApplication: null,
    setReservationApplication: () => {},
    accomodationId: null,
    setAccomodationId: () => {},
    language: "english",
    setLanguage: (lang: string) => {},
    getText: (key: string) => '',
};
export const AppContext = createContext<IAppContext>(defaultContext);
