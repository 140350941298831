import React, { useContext, useState } from "react";

import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
} from "../../utils/FormManager";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";

const name2validators = {
  email: [notEmptyValidator()],
  password: [lengthValidator(8)],
};

interface ICustomerLoginProps {
  setModalText: (msg: string) => void;
  setLoading: (x: boolean) => void;
}
export function CustomerLogin({
  setModalText,
  setLoading,
}: ICustomerLoginProps) {
  const { requestManager, setUser, getText } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  const navigate = useNavigate();

  const composeCodeData = () => {
    const data = {
      email: email.trim(),
      password: password.trim(),
    };
    const invalidFields = validateForm(data, name2validators);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages({ ...formMessages, ...msgs });
      return null;
    }
    return data;
  };
  const handleLogin = async () => {
    setLoading(true);
    const data = composeCodeData();
    if (!data) {
      setLoading(false);
      return;
    }
    const res = await requestManager.login(data.email, data.password);
    if (res.status === 401) {
      setLoading(false);
      setModalText(getText("m_invalid_creds"));
      return;
    } else if (res.status !== 200) {
      setLoading(false);
      alert("Debug: " + JSON.stringify(res));
      return;
    }
    const res_user = await requestManager.postJson(API_URLS.USER_GET);
    if (res_user.status !== 200) {
      setLoading(false);
      alert("Debug: " + JSON.stringify(res));
      return;
    }

    const user_ = res_user.data;

    setUser(user_);
    setLoading(false);
    navigate(WEB_ROUTERS.CUSTOMER_PROPERTIES);
  };
  const handleReset = () => {
    navigate(WEB_ROUTERS.CUSTOMER_PASSWORD_RESET);
  };
  const handleSeePrices = () => {
    navigate(WEB_ROUTERS.PRICES);
  }
  const handleNewManagerRegistration = () => {
    navigate(WEB_ROUTERS.CUSTOMER_REGISTER);
  }

  return (
    <div className="form-container w620_ifw" >
      <div className="form-card">
        <div
          className="card__header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3 className="card__header-title">{getText("h_login")}</h3>
        </div>

        <div className="control__wrap">
          <div className="form__control control_wide">
            <MyInput
              type="email"
              label={getText("l_email")}
              placeholder={getText("ph_email")}
              value={email}
              setValue={(v) => setEmail(v)}
              validators={name2validators["email"]}
              messages={formMessages["email"] || []}
              setMessages={(v) =>
                setFormMessages({ ...formMessages, email: v })
              }
            />
          </div>

          <div className="form__control control_wide">
            <MyInput
              type="password"
              label={getText("l_password")}
              placeholder={getText("ph_password")}
              value={password}
              setValue={(v) => setPassword(v)}
              validators={name2validators["password"]}
              messages={formMessages["password"] || []}
              setMessages={(v) =>
                setFormMessages({ ...formMessages, password: v })
              }
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="form__control" style={{ alignSelf: "flex-end" }}>
              <div
                className="link tx_primary"
                style={{ display: "inline-block", alignSelf: "flex-end" }}
                onClick={() => handleReset()}
              >
                {getText("btn_reset_password")}
              </div>
            </div>
            <div className="form__control">
              <div className="btn bg_primary" onClick={() => handleLogin()}>
                {getText("btn_login")}
              </div>
            </div>
            <div className="form__control" style={{width: "100%", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap"}}>
              <div className="btn bg_primary" onClick={() => handleSeePrices()}>
                {getText("btn_see_prices")}
              </div>

              <div className="btn bg_primary" onClick={() => handleNewManagerRegistration()}>
                {getText("btn_new_manager_registration")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
