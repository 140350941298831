import React, { useContext, useEffect, useRef, useState } from "react";
// import "./styles.css";
import { AppContext } from "../../utils/AppContext";
import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { MyFileInput } from "../common/MyFileInput";

export function BatchRegistrationPage() {
  const {
    // user,
    getText,
    requestManager,
  } = useContext(AppContext);

  const [file, setFile] = useState<any>(null);
  const [extraction, setExtraction] = useState<any>(null);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const inProgress = useRef(false);

  // const navigate = useNavigate();
  // if (!user) {
  //   navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  // }

  useEffect(() => {}, []);

  const handleDragOver = (event: any) => {
    // console.log("handleDragOver", event);
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    // console.log("handleDrop", event);
    event.preventDefault();
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...event.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file_ = item.getAsFile();
          setFile(file_);
          // console.log(`… file[${i}].name = ${file.name}`);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...event.dataTransfer.files].forEach((file_, i) => {
        setFile(file_);
        //   console.log(`… file[${i}].name = ${file.name}`);
      });
    }
  };

  const sendImages = async () => {
    setLoading(true);

    const files = []; // FIXME:
    if (file) {
      files.push(file);
    }
    if (!files.length) {
      setModalText(getText("m_no_data_specified"));
      setLoading(false);
      return;
    }
    const params = {};
    let res = null;
    try {
      res = await requestManager.uploadMultipart(
        API_URLS.MANAGER_REGISTER_BATCH,
        files,
        params,
        false
      );
    } catch {
      setModalText("Sorry, something went wrong");
      setExtraction({ valid: true });
      setLoading(false);
      return;
    }
    // console.log(res);

    if (res.status === 200) {
      const data = res["data"];
      const result = data["result"];
      setExtraction(result);

      if (data && data["message"]) {
        setModalText(data["message"]);
      } else {
        setModalText("Congratulations, file imported successfully");
      }
    } else if (res.status === 401) {
      setModalText(getText("m_failed_authorize"));
      setLoading(false);
      return;
    } else if (res.status === 409) {
      const data = JSON.parse(res["data"]);
      const result = data["result"];
      console.log("result 409", result);
      setExtraction(result);
      if (data["message"]) {
        setModalText(data["message"]);
      } else {
        setModalText(getText("m_conflict"));
      }
      // setModalText(getText("m_conflict") + ":\n" + data["message"]);
    } else {
      const data = JSON.parse(res["data"]);
      const result = data["result"];
      // console.log("result", result);
      setExtraction(result);
      if (data["message"]) {
        setModalText(data["message"]);
      } else {
        setModalText("something went wrong");
      }
    }

    setLoading(false);
  };

  const handleSendFile = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await sendImages();
    inProgress.current = false;
  };

  const handleDownloadTemplate = () => {
    window.location.href =
      "/templates/App-Roat-appartment-upload-template.xlsx";
  };

  const renderRowid2Errors = (rowid2errors: {
    [key: string]: { key: string; msg: string }[];
  }) => {
    const rows = Object.keys(rowid2errors).map((rowid: string) => {
      console.log(rowid2errors[rowid]);
      const errorKeys = rowid2errors[rowid]
        .map(({ key, msg }) => `key: ${key}, message: ${msg}`)
        .join(", ");
      return <p key={rowid}>{errorKeys}</p>;
    });
    return (
      <div>
        <h3>Invalid values</h3>
        {rows}
      </div>
    );
  };
  const renderRowidEmailExists = (rowid_email_exists: number[]) => {
    const rowids = rowid_email_exists.map((rowid) => rowid + 1).join(", ");
    return (
      <div>
        <h3>Rows with already imported emails:</h3>
        <p>{rowids}</p>
      </div>
    );
  };
  const renderRowidRoatNumberExists = (rowid_roat_number_exists: number[]) => {
    const rowids = rowid_roat_number_exists
      .map((rowid) => rowid + 1)
      .join(", ");
    return (
      <div>
        <h3>Rows with already imported HUTs:</h3>
        <p>{rowids}</p>
      </div>
    );
  };
  const renderRowidRoatInvalidCredentials = (
    rowid_roat_invalid_credentials: number[]
  ) => {
    const rowids = rowid_roat_invalid_credentials
      .map((rowid) => rowid + 1)
      .join(", ");
    return (
      <div>
        <h3>Rows with incorrect authorisation data in ROAT:</h3>
        <p>{rowids}</p>
      </div>
    );
  };

  const renderExtraction = (extraction: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "300px",
          overflowY: "auto",
          width: "100%",
        }}
      >
        {extraction["rowid2errors"] &&
          Object.keys(extraction["rowid2errors"]).length > 0 &&
          renderRowid2Errors(extraction["rowid2errors"])}
        {"rowid_email_exists" in extraction &&
          extraction["rowid_email_exists"].length > 0 &&
          renderRowidEmailExists(extraction["rowid_email_exists"])}
        {"rowid_roat_number_exists" in extraction &&
          extraction["rowid_roat_number_exists"].length > 0 &&
          renderRowidRoatNumberExists(extraction["rowid_roat_number_exists"])}
        {"rowid_roat_invalid_credentials" in extraction &&
          extraction["rowid_roat_invalid_credentials"].length > 0 &&
          renderRowidRoatInvalidCredentials(
            extraction["rowid_roat_invalid_credentials"]
          )}
      </div>
    );
  };

  return (
    <div className="page-content">
      {/* <h3
        className="header-tag header_big tx_gray off_small tx_med"
        style={{ fontSize: "16px" }}
      >
        {getText("h_guide")}
      </h3>
      <h3
        className="header-tag off_small tx_med"
        style={{ fontSize: "16px", paddingTop: 0 }}
      >
        {getText("t_guide")}
      </h3> */}
      {/* <div className="header-tag">
        <h3 className="header-tag__title header-tag__title_big">
          {getText("h_register_title")}
        </h3>
      </div> */}

      <div className="form__control" style={{ width: "200px" }}>
        <div
          className="btn bg_primary"
          onClick={() => handleDownloadTemplate()}
        >
          {getText("btn_download_template")}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          padding: "20px 0",
        }}
      >
        <div className="form-container w620_ifw">
          <div className="form-card">
            <div
              className="card__header"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h3>{getText("h_register_tourist_batch")}</h3>
            </div>
            <div className="control__wrap" style={{ flexDirection: "column" }}>
              <div className="form__control" style={{ width: "100%" }}>
                <div
                  onDrop={(event) => handleDrop(event)}
                  onDragOver={(event) => handleDragOver(event)}
                  className="control__drop"
                >
                  <div>{getText("t_drag_and_drop_excel")}</div>
                  <MyFileInput
                    label={""}
                    btn_label={getText("t_click_to_browse")}
                    setValue={(v: any) => setFile(v)}
                    mimes={
                      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    clsName="bg_primary"
                  />
                  {file && <p>{file.name}</p>}
                </div>
              </div>
              {/* <div className="form__control" style={{ alignSelf: "flex-end" }}>
                <div
                  className="btn bg_primary"
                  onClick={() => handleSendFile()}
                >
                  {getText("btn_process")}
                </div>
              </div> */}

              <div
                className="form__control"
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="btn bg_primary"
                  onClick={() => handleDownloadTemplate()}
                >
                  {getText("btn_download_template")}
                </div>

                <div
                  className="btn bg_primary"
                  onClick={() => handleSendFile()}
                >
                  {getText("btn_process")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-tag" style={{ fontSize: "16px" }}>
        {getText("t_customer_note")}
      </div>

      {modalText && extraction && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          {!extraction["valid"] && renderExtraction(extraction)}
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
}
