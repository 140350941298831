import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyToggle } from "../common/MyToggle";
import { MySelect } from "../common/MySelect";
import { MyInput } from "../common/MyInput";
import {
  IHeaderInfo,
  IReservation,
  IReservationsFilterOptions,
  IRow,
} from "../common/types";
import {
  createRows,
  filterRows,
  format_date,
  headerInfosDeposit,
  headerInfosIEAT,
  headerInfosInvoices,
  headerInfosReservations,
  sortRows,
  monthDiff,
} from "../../utils/helpers";
import { MyRadioButton } from "../common/MyButton";

interface IReservationsPageProps {
  reservationsInfo: IReservation[];
  tableName: string;
  onCancel: (
    reservation_application_id: number,
    canceledAt: string | null
  ) => Promise<void>;
  onDeposit: (
    reservation_application_id: number,
    depositAmount: number
  ) => Promise<void>;
  filterOptions: IReservationsFilterOptions;
  showAllGuests: boolean;
}

// function monthDiff(d1: Date, d2: Date) {
//   let months = 0;
//   months = (d2.getFullYear() - d1.getFullYear()) * 12;
//   months -= d1.getMonth();
//   months += d2.getMonth();
//   return months <= 0 ? 0 : months;
// }

export const ReservationsBlock: React.FC<IReservationsPageProps> = ({
  reservationsInfo,
  tableName,
  onCancel,
  onDeposit,
  filterOptions,
  showAllGuests,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);

  const [rowsRef, setRowsRef] = useState<IRow[]>([]);
  const [rowsFiltered, setRowsFiltered] = useState<IRow[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);

  const [deposit, setDeposit] = useState<number>(0);
  const [depositsRef, setDepositsRef] = useState<{ [key: number]: number }>({});
  const [deposits, setDeposits] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    // console.log("ReservationsBlock prepareRows", reservationsInfo, showAllGuests, tableName);
    prepareRows(reservationsInfo, showAllGuests);
  }, [reservationsInfo, showAllGuests, tableName]);

  useEffect(() => {
    // console.log("ReservationsBlock filterRows", tableName, rowsRef);
    // if (tableName === "invoices") {
    //   return;
    // }
    const rows_ = filterRows(
      rowsRef,
      filterOptions.reservationId,
      filterOptions.from,
      filterOptions.to,
      filterOptions.guestName
    );
    setRowsFiltered(rows_);
  }, [rowsRef, filterOptions, tableName]);

  useEffect(() => {
    const rows_ = sortRows(rowsFiltered, sortKey, sortAsc);
    setRows(rows_);
  }, [rowsFiltered, sortKey, sortAsc]);

  const handleChangeSortKey = (header: string | null) => {
    let asc = sortAsc;
    if (sortKey === header) {
      asc = !asc;
    } else {
      asc = false;
      setSortKey(header);
    }
    setSortAsc(asc);
  };

  const renderHeader = (headerInfos: IHeaderInfo[]) => {
    return (
      <tr>
        {headerInfos.map((hinfo, hi) => {
          const active_ = sortKey === hinfo.key;
          return (
            <th key={hinfo.key} onClick={() => handleChangeSortKey(hinfo.key)}>
              <div style={{ display: "flex", minWidth: "160px" }}>
                {hinfo.label}&nbsp;
                <MyToggle active={active_} />
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const prepareRows = (
    reservations_: IReservation[],
    allGuests: boolean = true
  ) => {
    const deposits_: { [key: number]: number } = {};
    reservations_.forEach((reservation) => {
      reservation.reservation_applications?.forEach((reservation_application) => {
        deposits_[reservation_application.id] =
        reservation_application.deposit_amount;
      });
    });
    setDepositsRef(deposits_);
    setDeposits({ ...deposits_ });
    // const allGuests = tableName === "reservations";
    const newRows = createRows(reservations_, allGuests);
    // console.log("newRows", newRows);
    if (tableName === "invoices") {
      const newRows_: IRow[] = [];

      newRows.forEach((row) => {
        const d1 = row.checkin ? new Date(row.checkin) : row.checkin;
        const d2 = row.checkout ? new Date(row.checkout) : row.checkout;
        if (d1 && d2) {
          const monthCount = monthDiff(d2, d1) + 1;
          for (let i = 0; i < monthCount; i++) {
            const d = new Date(d1.getFullYear(), d1.getMonth() + i);
            const dStr = d.getFullYear() + "-" + (d.getMonth() + 1); //d.toLocaleDateString("en-US");
            const row_ = { ...row, date: dStr };
            newRows_.push(row_);
          }
        }
      });
      const newRows_2: any[] = [];
      const date2set: any = {};
      newRows_.forEach((r: any) => {
        if (!(r.date in date2set)) {
          date2set[r.date] = new Set();
        }
        date2set[r.date].add(r.r_id);
      });
      Object.keys(date2set).forEach((date: any) => {
        const count = date2set[date].size;
        const invoiced = (9.88 * count).toFixed(2);
        newRows_2.push({ count, date, invoiced });
      });
      // console.log("newRows_2", newRows_2);

      setRowsRef(newRows_2);
      return newRows_2;
    }
    setRowsRef(newRows);
    return newRows;
  };

  const renderRowsReservations = () => {
    return rows.map((row) => {
      return (
        <tr className="reservations-table__row">
          <td>
            <div className="reservations-table__row__cell">{row.name}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.checkin}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.checkout}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.nights}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.ng16}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.nle16}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <MySelect
                options={[{ value: -1, label: "No" }]}
                value={-1}
                onChange={(v: any) => {}}
                cls="outline"
              />
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={row.canceled ? "btn filled" : "btn outline"}
                onClick={() =>
                  onCancel(
                    row.ra_id,
                    row.canceled ? null : format_date(new Date())
                  )
                }
              >
                {row.canceled ? row.canceled_at : "cancel"}
              </div>
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={
                  row.transmitted
                    ? "filled filled_green"
                    : "outline outline_green"
                }
                onClick={() => {}}
              >
                {row.transmitted ? row.transmitted_at : ""}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderRowsIEAT = () => {
    return rows.map((row) => {
      return (
        <tr className="reservations-table__row">
          <td>
            <div className="reservations-table__row__cell">{row.name}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.checkin}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.checkout}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.nights}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.ng16}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.nle16}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.ieat}&nbsp;€
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.igi}&nbsp;€
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={row.canceled ? "btn filled" : "btn outline"}
                onClick={() =>
                  onCancel(
                    row.ra_id,
                    row.canceled ? null : format_date(new Date())
                  )
                }
              >
                {row.canceled ? row.canceled_at : "cancel"}
              </div>
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={
                  row.ieat_paid
                    ? "filled filled_green"
                    : "outline outline_green"
                }
                onClick={() => {}}
              >
                {row.ieat_paid_at}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderRowsDeposit = () => {
    const currentDate = new Date();
    const currentDateTime = currentDate.getTime();
    return rows.map((row) => {
      const kept =
        deposits[row.ra_id] === depositsRef[row.ra_id] &&
        deposits[row.ra_id] >= 0;
      let paid = false;
      if (row.checkout) {
        const checkoutTime = new Date(row.checkout).getTime();
        paid = currentDateTime > checkoutTime;
      }

      return (
        <tr className="reservations-table__row">
          <td>
            <div className="reservations-table__row__cell">{row.name}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.checkin}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.checkout}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.nights}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.ng16}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.nle16}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <MyInput
                type="number"
                label={""}
                value={deposits[row.ra_id]}
                setValue={(v) =>
                  setDeposits((d) => ({ ...d, [row.ra_id]: +v }))
                }
                placeholder="300 €"
              />
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={kept ? "btn filled" : "btn outline"}
                onClick={() =>
                  !kept && onDeposit(row.ra_id, deposits[row.ra_id])
                }
              >
                {kept ? "kept" : "keep"}
              </div>
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={
                  kept && paid ? "filled filled_green" : "outline outline_green"
                }
              >
                {row.checkout}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderRowsInvoices = () => {
    return rows.map((row: any) => {
      // console.log(row);
      return (
        <tr className="reservations-table__row">
          <td>
            <div className="reservations-table__row__cell">{row.count}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">{row.date}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.invoiced} €
            </div>
          </td>
          <td>
            <div>
              <div>
                <MyRadioButton checked={false} />
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  // ----------

  const renderTableReservations = () => {
    return (
      <table className="reservations-table combined_1_to_6">
        <thead>{renderHeader(headerInfosReservations)}</thead>
        <tbody>{renderRowsReservations()}</tbody>
      </table>
    );
  };

  const renderTableIEAT = () => {
    return (
      <table className="reservations-table combined_1_to_6 combined_7 combined_8">
        <thead>{renderHeader(headerInfosIEAT)}</thead>
        <tbody>{renderRowsIEAT()}</tbody>
      </table>
    );
  };

  const renderTableDeposit = () => {
    return (
      <table className="reservations-table combined_1_to_6">
        <thead>{renderHeader(headerInfosDeposit)}</thead>
        <tbody>{renderRowsDeposit()}</tbody>
      </table>
    );
  };

  const renderTableInvoices = () => {
    return (
      <table className="reservations-table combined_1_to_3">
        <thead>{renderHeader(headerInfosInvoices)}</thead>
        <tbody>{renderRowsInvoices()}</tbody>
      </table>
    );
  };

  const renderTableByName = (name: string) => {
    if (name === "reservations") {
      return renderTableReservations();
    } else if (name === "ieat") {
      return renderTableIEAT();
    } else if (name === "deposit") {
      return renderTableDeposit();
    } else if (name === "invoices") {
      return renderTableInvoices();
    }
    return null;
  };

  return (
    <div>
      {tableName === "deposit" && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <MyInput
            type="number"
            label={"Set Deposit"}
            value={deposit}
            setValue={(v) => setDeposit(v)}
            placeholder="300 €"
          />
        </div>
      )}
      {renderTableByName(tableName)}
      {tableName === "invoices" && (
        <div className="control_right">
          <div className="btn bg_primary" onClick={() => {}}>
            {getText("btn_download")}
          </div>
        </div>
      )}
    </div>
  );
};
