import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyLoading, MyModal } from "../common/MyModal";
import { RoatCredentials } from "./RoatCredentials";

export const RoatCredentialsPage: React.FC<{}> = () => {
  const { getText } = useContext(AppContext);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div className="page-content">
      
      <div className="header-tag">
        <h3 className="header-tag__title">{getText("h_roat_update")}</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "40px", paddingTop: "20px" }}>
        <RoatCredentials setModalText={setModalText} setLoading={setLoading} />
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
};
