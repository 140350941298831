import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyToggle } from "../common/MyToggle";
import { MyInput } from "../common/MyInput";
import { IHeaderInfo, IReservationInfo } from "../common/types";
import { makeDeepCopy, sortRows } from "../../utils/helpers";
import { MyRadioButton } from "../common/MyButton";

interface IReservationTableProps {
  datas: IReservationInfo[];
  saveDatas: (datas: IReservationInfo[]) => void;
  selected: IReservationInfo | null;
  onSelect: (data: IReservationInfo) => void;
  onDelete: () => void;
}

const headerInfos: IHeaderInfo[] = [
  { label: "Edit", key: "" },
  { label: "Room Code", key: "room_code" },
  { label: "Roat Code", key: "roat_number" },
  { label: "Address", key: "address" },
  { label: "City", key: "postal_code_city" },
  { label: "Date register", key: "created_at" },
  { label: "Reservations", key: "reservation_count" },
  { label: "Extraction Att.", key: "extraction_attempts_sum" },
  { label: "Success", key: "extraction_success_sum" },
  { label: "Rate", key: "" },
  { label: "Last check-in", key: "last_check_in" },
  { label: "Fee applied", key: "fee_applied" },
  { label: "Total Invoiced", key: "total" },
  { label: "Visualize", key: "" },
];

export const ReservationTable: React.FC<IReservationTableProps> = ({
  datas,
  saveDatas,
  selected,
  onSelect,
  onDelete,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [rowsEdit, setRowsEdit] = useState<IReservationInfo[]>([]);
  const [rowsSorted, setRowsSorted] = useState<IReservationInfo[]>([]);

  useEffect(() => {
    setRowsEdit(makeDeepCopy(datas));
  }, [datas]);

  useEffect(() => {
    const rows = sortRows(rowsEdit, sortKey, sortAsc);
    setRowsSorted(rows);
  }, [rowsEdit, sortKey, sortAsc]);

  const handleChangeSortKey = (header: string | null) => {
    let asc = sortAsc;
    if (sortKey === header) {
      asc = !asc;
    } else {
      asc = false;
      setSortKey(header);
    }
    setSortAsc(asc);
  };

  const renderHeader = () => {
    return (
      <tr>
        {headerInfos.map((hinfo, hi) => {
          const active_ = sortKey === hinfo.key;
          return (
            <th key={hi} onClick={() => handleChangeSortKey(hinfo.key)}>
              <div style={{ display: "flex", minWidth: "160px" }}>
                {hinfo.label}&nbsp;
                <MyToggle active={active_} />
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const getRate = (row: IReservationInfo) => {
    const rate = row.extraction_attempts_sum
      ? row.extraction_success_sum / row.extraction_attempts_sum
      : 1;
    const rateFixed = rate.toFixed(2);
    return rateFixed;
  };
  const renderRows = () => {
    return rowsSorted.map((row) => {
      const rate = getRate(row);
      return (
        <tr key={row.id}>
          <td>
            <div>
              <MyRadioButton checked={false} />
            </div>
          </td>
          <td>
            <div>{row.room_code}</div>
          </td>
          <td>
            <div>{row.roat_number}</div>
          </td>
          <td>
            <div>{row.address}</div>
          </td>
          <td>
            <div>{row.postal_code_city}</div>
          </td>
          <td>
            <div>{row.created_at}</div>
          </td>
          <td>
            <div>{row.reservation_count}</div>
          </td>

          <td>
            <div>{row.extraction_attempts_sum}</div>
          </td>
          <td>
            <div>{row.extraction_success_sum}</div>
          </td>
          <td>
            <div>{rate}</div>
          </td>
          <td>
            <div>{row.last_check_in}</div>
          </td>
          <td>
            <div>1.99</div>
          </td>
          <td>
            <div>0</div>
          </td>
          <td onClick={() => onSelect(row)}>
            <MyRadioButton checked={selected?.id === row.id} />
          </td>
        </tr>
      );
    });
  };

  const updateRow = (newValue: IReservationInfo) => {
    setRowsEdit((rows) =>
      rows.map((row) => (row.id !== newValue.id ? row : newValue))
    );
  };

  const renderRowsEdit = () => {
    return rowsSorted.map((row) => {
      const rate = getRate(row);
      return (
        <tr key={row.id}>
          <td></td>
          <td>
            <div>{row.room_code}</div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.roat_number}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    roat_number: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.address}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    address: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.postal_code_city}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    postal_code_city: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>{row.created_at}</div>
          </td>
          <td>
            <div>{row.reservation_count}</div>
          </td>

          <td>
            <div>{row.extraction_attempts_sum}</div>
          </td>
          <td>
            <div>{row.extraction_success_sum}</div>
          </td>
          <td>
            <div>{rate}</div>
          </td>
          <td>
            <div>{row.last_check_in}</div>
          </td>
          <td>
            <div>1.99</div>
          </td>
          <td>
            <div>0</div>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  const checkModified = () => {
    return JSON.stringify(datas) !== JSON.stringify(rowsEdit);
  };

  const modified = checkModified();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "32px" }}>
        <div className="btn btn_bg" onClick={() => setEditMode(true)}>
          <img
            src="/images/edit.svg"
            className="filter_gray"
            style={{ width: "1.5em" }}
            alt="edit"
          />
        </div>
        <div className="btn btn-with-image" onClick={() => onDelete()}>
          <img
            src="/images/delete2.svg"
            className="filter_gray"
            style={{ width: "1.2em" }}
            alt="delete2"
          />
        </div>
      </div>
      <div className="form-card card_scroll">
        <table>
          <thead>{renderHeader()}</thead>
          <tbody>{editMode ? renderRowsEdit() : renderRows()}</tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "32px" }}>
        {editMode && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div
              className="btn bg_secondary"
              onClick={() => setEditMode(false)}
            >
              Stop Editing
            </div>
          </div>
        )}
        {modified && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div className="btn bg_primary" onClick={() => saveDatas(rowsEdit)}>
              Save
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
