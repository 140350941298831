import React, { useContext, useState, useRef } from "react";
import {
  isValidDateStr,
  notEmptyValidator,
  validateForm,
  inDictValidator,
} from "../../utils/FormManager";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { MyDateInput } from "../common/MyDateInput";
import { COUNTRY_TO_IDX, COUNTRY_ENGLISH } from "../../utils/constants";
import { MyAutocompleteInput } from "../common/MyAutocompleteInput";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  place_of_birth: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")], // FIXME: translate this
  citizenship: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  residency: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  birth_date: [isValidDateStr()],
};

interface IGuestStepsEditProps {
  idx: number;
  guest: any;
  setGuest: (v: any) => void;
  onDelete: () => void;
  onSave: () => void;
  saved: boolean;
  onBadSave?: () => void;
}

export const GuestStepsEdit: React.FC<IGuestStepsEditProps> = ({
  idx,
  guest,
  setGuest,
  onDelete,
  onSave,
  saved,
  onBadSave = null,
}) => {
  const { getText } = useContext(AppContext);

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  // const guest_id = guest["id"];
  const firstname = guest["firstname"];
  const lastname = guest["lastname"];
  const birth_date = guest["birth_date"] || "";
  const citizenship = guest["citizenship"];
  const residency = guest["residency"];
  const place_of_birth = guest["place_of_birth"];
  const id_images = guest["id_images"];

  const haveValidImages = id_images?.length > 0;

  const handleSave = async () => {
    const invalidFields = validateForm(guest, name2validators);
    if (invalidFields.length === 0) {
      onSave();
    } else {
      if (onBadSave) {
        onBadSave();
      }
    }
  };

  return (
    <div key={guest["id"]} className="form-card">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 className="">Guest #{idx + 1}</h4>
        <div
          className="btn"
          style={{
            minWidth: "auto",
            padding: "0",
            alignSelf: "center",
            justifySelf: "flex-end",
            fontSize: "14px",
          }}
          onClick={() => onDelete()}
        >
          <img
            src="/images/delete.svg"
            className="filter_gray"
            style={{ width: "1.2em" }}
          />
        </div>
      </div>
      {/* <div className="control__wrap"> */}
      <div className="form__control">
        <MyInput
          type="text"
          label={getText("t_firstname")}
          placeholder={getText("ph_firstname")}
          value={firstname}
          setValue={(v) => setGuest({ ...guest, firstname: v })}
          validators={name2validators["firstname"]}
          messages={formMessages["firstname"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, firstname: v })
          }
          disabled={!haveValidImages}
        />
      </div>
      <div className="form__control">
        <MyInput
          type="text"
          label={getText("t_lastname")}
          placeholder={getText("ph_lastname")}
          value={lastname}
          setValue={(v) => setGuest({ ...guest, lastname: v })}
          validators={name2validators["lastname"]}
          messages={formMessages["lastname"] || []}
          setMessages={(v) => setFormMessages({ ...formMessages, lastname: v })}
          disabled={!haveValidImages}
        />
      </div>

      <div className="form__control">
        <MyDateInput
          label={getText("t_birth_date")}
          value={birth_date}
          setValue={(v) => setGuest({ ...guest, birth_date: v })}
          validators={name2validators["birth_date"]}
          messages={formMessages["birth_date"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, checkout_date: v })
          }
          disabled={!haveValidImages}
        />
      </div>
      <div className="form__control">
        <MyAutocompleteInput
          label={getText("t_citizenship")}
          placeholder={getText("ph_france")}
          values={COUNTRY_ENGLISH}
          value={citizenship}
          setValue={(v) => setGuest({ ...guest, citizenship: v })}
          validators={name2validators["citizenship"]}
          messages={formMessages["citizenship"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, citizenship: v })
          }
          disabled={!haveValidImages}
        />
      </div>
      <div className="form__control">
        <MyAutocompleteInput
          label={getText("t_residency")}
          placeholder={getText("ph_france")}
          values={COUNTRY_ENGLISH}
          value={residency}
          setValue={(v) => setGuest({ ...guest, residency: v })}
          validators={name2validators["residency"]}
          messages={formMessages["residency"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, residency: v })
          }
          disabled={!haveValidImages}
        />
      </div>
      <div className="form__control">
        <MyAutocompleteInput
          label={getText("t_place_of_birth")}
          placeholder={getText("ph_france")}
          values={COUNTRY_ENGLISH}
          value={place_of_birth}
          setValue={(v) => setGuest({ ...guest, place_of_birth: v })}
          validators={name2validators["place_of_birth"]}
          messages={formMessages["place_of_birth"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, place_of_birth: v })
          }
          disabled={!haveValidImages}
        />
      </div>
      {!saved && haveValidImages && (
        <div className="control_center">
          <div className="btn bg_primary" onClick={() => handleSave()}>
            {getText("btn_save_guest")}
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};
