import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyToggle } from "../common/MyToggle";
import { MyInput } from "../common/MyInput";
import {
  IHeaderInfo,
  IReservationApplication,
  IReservationInfo,
} from "../common/types";
import { format_date, makeDeepCopy, sortRows } from "../../utils/helpers";
import { MyRadioButton } from "../common/MyButton";
import { MyDateInput } from "../common/MyDateInput";

interface IReservationApplicationTableProps {
  datas: IReservationApplication[];
  saveDatas: (datas: IReservationApplication[]) => void;
  selected: IReservationApplication | null;
  onSelect: (data: IReservationApplication) => void;
  onDelete: () => void;
}

const headerInfos: IHeaderInfo[] = [
  { label: "Edit", key: "" },
  { label: "Room Code", key: "room_code" },
  { label: "Reservation Code", key: "reservationCode" },
  { label: "Created At", key: "createdAt" },
  { label: "Check in", key: "checkinDate" },
  { label: "Check out", key: "checkoutDate" },
  { label: "Deposit", key: "depositAmount" },
  { label: "Deposit Paid", key: "depositPaidAt" },
  { label: "Cancel", key: "canceledAt" },
  { label: "Date cancelled", key: "canceledAt" },
  { label: "Date checked", key: "finalizedAt" },
  { label: "Transmitted", key: "transmittedAt" },
  { label: "Date transmitted", key: "transmittedAt" },
  { label: "Visualize", key: "" },
];

export const ReservationApplicationTable: React.FC<IReservationApplicationTableProps> = ({
  datas,
  saveDatas,
  selected,
  onSelect,
  onDelete,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [rowsEdit, setRowsEdit] = useState<IReservationApplication[]>([]);
  const [rowsSorted, setRowsSorted] = useState<IReservationApplication[]>([]);

  useEffect(() => {
    setRowsEdit(makeDeepCopy(datas));
  }, [datas]);

  useEffect(() => {
    const rows = sortRows(rowsEdit, sortKey, sortAsc);
    setRowsSorted(rows);
  }, [rowsEdit, sortKey, sortAsc]);

  const handleChangeSortKey = (header: string | null) => {
    let asc = sortAsc;
    if (sortKey === header) {
      asc = !asc;
    } else {
      asc = false;
      setSortKey(header);
    }
    setSortAsc(asc);
  };

  const renderHeader = () => {
    return (
      <tr>
        {headerInfos.map((hinfo, hi) => {
          const active_ = sortKey === hinfo.key;
          return (
            <th key={hi} onClick={() => handleChangeSortKey(hinfo.key)}>
              <div style={{ display: "flex", minWidth: "160px" }}>
                {hinfo.label}&nbsp;
                <MyToggle active={active_} />
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const updateRow = (newValue: IReservationApplication) => {
    setRowsEdit((rows) =>
      rows.map((row) => (row.id !== newValue.id ? row : newValue))
    );
  };

  const renderRows = () => {
    return rowsSorted.map((row) => {
      return (
        <tr key={row.id}>
          <td>
            <div>
              <MyRadioButton checked={false} />
            </div>
          </td>
          <td>
            <div>{row.room_code}</div>
          </td>
          <td>
            <div>{row.reservation_code}</div>
          </td>
          <td>
            <div>{row.created_at}</div>
          </td>
          <td>
            <div>{row.checkin_date}</div>
          </td>
          <td>
            <div>{row.checkout_date}</div>
          </td>
          <td>
            <div>{row.deposit_amount}</div>
          </td>

          <td>
            <div>{row.deposit_paid_at}</div>
          </td>
          <td>
            <div>
              <MyRadioButton checked={row.canceled_at} />
            </div>
          </td>
          <td>
            <div>{row.canceled_at}</div>
          </td>
          <td>
            <div>{row.finalized_at}</div>
          </td>
          <td>
            <div>
              <MyRadioButton checked={row.transmitted_at} />
            </div>
          </td>
          <td>
            <div>{row.transmitted_at}</div>
          </td>
          <td onClick={() => onSelect(row)}>
            <MyRadioButton checked={selected?.id === row.id} />
          </td>
        </tr>
      );
    });
  };

  const renderRowsEdit = () => {
    return rowsSorted.map((row) => {
      return (
        <tr key={row.id}>
          <td></td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.room_code}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    room_code: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.reservation_code}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    reservation_code: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>{row.created_at}</div>
          </td>
          <td>
            <div>
              <MyDateInput
                value={row.checkin_date}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    checkin_date: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyDateInput
                value={row.checkout_date}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    checkout_date: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="number"
                label={""}
                value={row.deposit_amount}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    deposit_amount: v,
                  })
                }
              />
            </div>
          </td>

          <td>
            <div>
              <MyDateInput
                value={row.deposit_paid_at}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    deposit_paid_at: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyRadioButton
                checked={row.canceled_at}
                handleClick={() =>
                  updateRow({
                    ...row,
                    canceled_at: row.canceled_at ? "" : format_date(new Date()),
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>{row.canceled_at}</div>
          </td>
          <td>
            <div>
              <MyDateInput
                value={row.finalized_at}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    finalized_at: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyRadioButton checked={row.transmitted_at} />
            </div>
          </td>
          <td>
            <div>
              <MyDateInput
                value={row.transmitted_at}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    transmitted_at: v,
                  })
                }
              />
            </div>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  const checkModified = () => {
    return JSON.stringify(datas) !== JSON.stringify(rowsEdit);
  };

  const modified = checkModified();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "32px" }}>
        <div className="btn btn_bg" onClick={() => setEditMode(true)}>
          <img
            src="/images/edit.svg"
            className="filter_gray"
            style={{ width: "1.5em" }}
            alt="edit"
          />
        </div>
        <div className="btn btn-with-image" onClick={() => onDelete()}>
          <img
            src="/images/delete2.svg"
            className="filter_gray"
            style={{ width: "1.2em" }}
            alt="delete2"
          />
        </div>
      </div>
      <div className="form-card card_scroll">
        <table>
          <thead>{renderHeader()}</thead>
          <tbody>{editMode ? renderRowsEdit() : renderRows()}</tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "32px" }}>
        {editMode && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div
              className="btn bg_secondary"
              onClick={() => setEditMode(false)}
            >
              Stop Editing
            </div>
          </div>
        )}
        {modified && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div className="btn bg_primary" onClick={() => saveDatas(rowsEdit)}>
              Save
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
