import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import { MyRadioButton } from "../common/MyButton";
import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";
import { MyInput } from "../common/MyInput";
import { MyDateInput } from "../common/MyDateInput";
import { makeDeepCopy } from "../../utils/helpers";
import { CustomerTable } from "./CustomerTable";
import {
  ICustomerInfo,
  IGuest,
  IReservationApplication,
  IReservationInfo,
} from "../common/types";
import { ReservationTable } from "./ReservationTable";
import { ReservationApplicationTable } from "./ReservationApplicationTable";
import { GuestTable } from "./GuestTable";

function format_date(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return [
    year,
    (month > 9 ? "" : "0") + month,
    (day > 9 ? "" : "0") + day,
  ].join("-");
}

export function AdminDashboard2() {
  const { requestManager, getText } = useContext(AppContext);

  const [customers, setCustomers] = useState<ICustomerInfo[]>([]);
  const [reservations, setReservations] = useState<any[]>([]);
  const [reservationApplications, setReservationApplications] = useState<any[]>(
    []
  );

  const [selectedCustomer, setSelectedCustomer] =
    useState<ICustomerInfo | null>(null);
  const [selectedReservation, setSelectedReservation] =
    useState<IReservationInfo | null>(null);
  const [selectedReservationApplication, setSelectedReservationApplication] =
    useState<IReservationApplication | null>(null);
  const [selectedGuest, setSelectedGuest] = useState<IGuest | null>(null);

  const [visualizeLevel, setVisualizeLevel] = useState(0);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const inProgress = useRef<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (inProgress.current) {
      return;
    }
    _loadCustomers();
  }, []);
  useEffect(() => {
    if (inProgress.current) {
      return;
    }
    if (!selectedCustomer) {
      setReservations([]);
      setReservationApplications([]);
      return;
    }
    _loadReservations();
  }, [selectedCustomer]);
  useEffect(() => {
    if (inProgress.current) {
      return;
    }
    if (!selectedReservation) {
      setReservationApplications([]);
      return;
    }
    _loadReservationApplications();
  }, [selectedReservation]);

  const _loadCustomers = async () => {
    if (inProgress.current) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    handleDeselectCustomer();
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_MANAGERS_GETALL,
      {}
    );
    const succ = _checkResponse(resp);

    inProgress.current = false;
    setLoading(false);
    if (succ) {
      setCustomers(resp.data);
    }
  };
  const _loadReservations = async () => {
    if (inProgress.current || !selectedCustomer) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    handleDeselectReservation();
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATION_INFO_GETALL,
      { user_id: selectedCustomer.id }
    );
    const succ = _checkResponse(resp);
    inProgress.current = false;
    setLoading(false);
    if (succ) {
      setReservations(resp.data);
    }
  };
  const _loadReservationApplications = async () => {
    if (inProgress.current || !selectedReservation) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    handleDeselectReservationApplication();
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATION_APPLICATION_INFO_GETALL,
      { room_code: selectedReservation.room_code }
    );
    const succ = _checkResponse(resp);
    inProgress.current = false;
    setLoading(false);
    if (succ) {
      setReservationApplications(resp.data);
    }
  };
  const _checkResponse = (resp: any) => {
    if (resp.status === 401 || resp.status === 403) {
      navigate(WEB_ROUTERS.ADMIN_LOGIN);
    }
    // TODO: rep check general
    if (resp.status === 401) {
      setModalText(getText("m_failed_authorize"));
      return false;
    }
    if (resp.status !== 200) {
      setModalText(getText("m_res_load_failed"));
      return false;
    }
    return true;
  };

  const handleDeselectGlobal = () => {
    setSelectedGuest(null);
    setSelectedReservationApplication(null);
    setSelectedReservation(null);
    setSelectedCustomer(null);
  };
  const handleDeselectCustomer = () => {
    setSelectedGuest(null);
    setSelectedReservationApplication(null);
    setSelectedReservation(null);
    setSelectedCustomer(null);
  };
  const handleDeselectReservation = () => {
    setSelectedGuest(null);
    setSelectedReservationApplication(null);
    setSelectedReservation(null);
  };
  const handleDeselectReservationApplication = () => {
    setSelectedGuest(null);
    setSelectedReservationApplication(null);
  };

  const handleRemoveCustomer = async () => {
    const confirmed = window.confirm(
      "Do you really want to delete the Customer?"
    );
    if (!confirmed) {
      return;
    }
    if (!selectedCustomer || inProgress.current) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    const success = await _saveCustomer({...selectedCustomer, valid: false});
    setLoading(false);
    inProgress.current = false;
    _loadCustomers();
  };
  const handleRemoveReservation = async () => {
    const confirmed = window.confirm(
      "Do you really want to delete the Property?"
    );
    if (!confirmed) {
      return;
    }
    if (!selectedReservation || inProgress.current) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    const success = await _saveReservation({...selectedReservation, valid: false});
    setLoading(false);
    inProgress.current = false;
    _loadReservations();
  };
  const handleRemoveReservationApplication = async () => {
    const confirmed = window.confirm(
      "Do you really want to delete the Reservation?"
    );
    if (!confirmed) {
      return;
    }
    if (!selectedReservationApplication || inProgress.current) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    const success = await _saveReservationApplication({...selectedReservationApplication, valid: false});

    setLoading(false);
    inProgress.current = false;
    _loadReservationApplications();
  };
  const handleRemoveGuest = async () => {
    const confirmed = window.confirm(
      "Do you really want to delete the Guest?"
    );
    if (!confirmed) {
      return;
    }
    if (!selectedGuest || !selectedReservationApplication || inProgress.current) {
      return;
    }
    inProgress.current = true;
    setLoading(true);
    const success = await _saveGuest({...selectedGuest,valid:false}, selectedReservationApplication.id);
    setLoading(false);
    inProgress.current = false;
    _loadReservationApplications();
  };

  const handleRefresh = async () => {
    if (selectedReservationApplication || selectedReservation) {
      _loadReservationApplications();
    } else if (selectedCustomer) {
      _loadReservations();
    } else {
      _loadCustomers();
    }
  };

  const _saveCustomer = async (customer: ICustomerInfo) => {
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_USERS_SAVE,
      customer
    );
    const succ = resp.status === 200;
    return succ;
  };
  const _saveReservation = async (reservation: IReservationInfo) => {
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATIONS_SAVE,
      reservation
    );
    const succ = resp.status === 200;
    return succ;
  };
  const _saveReservationApplication = async (
    reservationApplication: IReservationApplication
  ) => {
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATION_APPLICATIONS_SAVE,
      reservationApplication
    );
    const succ = resp.status === 200;
    return succ;
  };
  const _saveGuest = async (
    guest: IGuest,
    reservation_application_id: number
  ) => {
    const resp = await requestManager.postJson(API_URLS.ADMIN_V2_GUESTS_SAVE, {
      ...guest,
      reservation_application_id,
    });
    const succ = resp.status === 200;
    return succ;
  };

  const saveCustomers = async (data: ICustomerInfo[]) => {
    const ref = customers;
    if (ref.length !== data.length) {
      setModalText("Cannot save changes");
      return;
    }
    inProgress.current = true;
    setLoading(true);
    let success = true;
    for (let i = 0; i < ref.length; i++) {
      if (JSON.stringify(ref[i]) !== JSON.stringify(data[i])) {
        const succ_ = await _saveCustomer(data[i]);
        success = success && succ_;
      }
    }
    setLoading(false);
    inProgress.current = false;
    _loadCustomers();
  };
  const saveReservations = async (data: IReservationInfo[]) => {
    const ref = reservations;
    if (ref.length !== data.length) {
      setModalText("Cannot save changes");
      return;
    }
    inProgress.current = true;
    setLoading(true);
    let success = true;
    for (let i = 0; i < ref.length; i++) {
      if (JSON.stringify(ref[i]) !== JSON.stringify(data[i])) {
        const succ_ = await _saveReservation(data[i]);
        success = success && succ_;
      }
    }
    setLoading(false);
    inProgress.current = false;
    _loadReservations();
  };
  const saveReservationApplications = async (
    data: IReservationApplication[]
  ) => {
    const ref = reservationApplications;
    if (ref.length !== data.length) {
      setModalText("Cannot save changes");
      return;
    }
    inProgress.current = true;
    setLoading(true);
    let success = true;
    for (let i = 0; i < ref.length; i++) {
      if (JSON.stringify(ref[i]) !== JSON.stringify(data[i])) {
        const succ_ = await _saveReservationApplication(data[i]);
        success = success && succ_;
      }
    }
    setLoading(false);
    inProgress.current = false;
    _loadReservationApplications();
  };
  const saveGuests = async (data: IGuest[]) => {
    const ref = selectedReservationApplication?.guests || [];
    if (!selectedReservationApplication || ref.length !== data.length) {
      setModalText("Cannot save changes");
      return;
    }
    inProgress.current = true;
    setLoading(true);
    let success = true;
    for (let i = 0; i < ref.length; i++) {
      if (JSON.stringify(ref[i]) !== JSON.stringify(data[i])) {
        const succ_ = await _saveGuest(
          data[i],
          selectedReservationApplication.id
        );
        success = success && succ_;
      }
    }
    setLoading(false);
    inProgress.current = false;
    _loadReservationApplications();
  };

  return (
    <div className="page-content">
      <h3
        className="header"
        style={{ fontSize: "36px", textAlign: "center", paddingTop: "20px" }}
      >
        {getText("t_admin_level")}
      </h3>
      <div>
        <div className="dashboard_container">
          <div style={{ display: "flex", gap: "1em" }}>
            <div className="btn bg_primary" onClick={() => handleDeselectGlobal()}>
              {getText("btn_global")}
            </div>
            <div className="btn bg_primary" onClick={() => handleDeselectCustomer()}>
              {getText("btn_customer")}
            </div>
            <div
              className="btn bg_primary"
              onClick={() => handleDeselectReservation()}
            >
              {getText("btn_reservation")}
            </div>
            <div
              className="btn bg_primary"
              onClick={() => handleDeselectReservationApplication()}
            >
              {getText("btn_reservation_application")}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p className="modal-text">{getText("t_customer_details")}</p>
              </div>
              <div>
                <CustomerTable
                  datas={customers}
                  saveDatas={(datas: ICustomerInfo[]) => saveCustomers(datas)}
                  selected={selectedCustomer}
                  onSelect={(customer) => setSelectedCustomer(customer)}
                  onDelete={() => handleRemoveCustomer()}
                />
              </div>
            </div>
            {selectedCustomer && (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="modal-text">
                    {getText("t_reservation_details")}
                  </p>
                </div>

                <div>
                  <ReservationTable
                    datas={reservations}
                    saveDatas={(datas: IReservationInfo[]) =>
                      saveReservations(datas)
                    }
                    selected={selectedReservation}
                    onSelect={(data: IReservationInfo) =>
                      setSelectedReservation(data)
                    }
                    onDelete={() => handleRemoveReservation()}
                  />
                </div>
              </div>
            )}
            {selectedReservation && (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="modal-text">
                    {getText("t_reservation_application_details")}
                  </p>
                </div>
                <div>
                  <ReservationApplicationTable
                    datas={reservationApplications}
                    saveDatas={(datas: IReservationApplication[]) =>
                      saveReservationApplications(datas)
                    }
                    selected={selectedReservationApplication}
                    onSelect={(v) => setSelectedReservationApplication(v)}
                    onDelete={() => handleRemoveReservationApplication()}
                  />
                </div>
              </div>
            )}
            {selectedReservationApplication && (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="modal-text">{getText("t_guest_details")}</p>
                </div>
                <div>
                  <GuestTable
                    datas={selectedReservationApplication?.guests || []}
                    saveDatas={(datas: IGuest[]) => saveGuests(datas)}
                    selected={selectedGuest}
                    onSelect={(v) => setSelectedGuest(v)}
                    onDelete={() => handleRemoveGuest()}
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <div style={{ display: "flex" }}>
              <div
                className="btn"
                style={{
                  minWidth: "auto",
                  padding: "0.5em",
                  alignSelf: "center",
                  justifySelf: "flex-end",
                }}
                onClick={() => handleRefresh()}
              >
                <img
                  src="/images/refresh.svg"
                  className="filter_gray"
                  style={{ width: "2em" }}
                  alt="refresh"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
}
