export const API_URLS= {
  LOGIN: "/api/login_check",
  REGISTER: "/api/register",
  REFRESH: "/api/token/refresh",
  LOGOUT: "/api/logout",
  PASSWORD_GENERATE: "/api/password/generate",
  PASSWORD_VERIFY: "/api/password/verify",
  USER_CODE_VERIFY: "/api/user/code/verify",
  USER_CODE_RESEND: "/api/user/code/resend",
  USER_PASSWORD_RESETCODE: "/api/user/password/reset_code",
  USER_PASSWORD_CHECKCODE: "/api/user/password/check_code",
  USER_PASSWORD_RESET: "/api/user/password/reset",

  // MANAGER_RESERVATIONS_APPLICATIONS_FIND:
  //   "/api/manager/reservations/applications/find",
  // MANAGER_RESERVATIONS_APPLICATIONS_SENDCHANGECODE:
  //   "/api/manager/reservations/applications/send_change_code",
  // MANAGER_RESERVATIONS_APPLICATIONS_UPDATE:
  //   "/api/manager/reservations/applications/update",
  // MANAGER_RESERVATIONS_GETALL: "/api/manager/reservations/getall",
  // MANAGER_RESERVATIONS_GET: "/api/manager/reservations/get",
  // MANAGER_RESERVATIONS: "/api/manager/reservations",

  // TOURIST_RESERVATIONS_APPLICATIONS_GET:
  //   "/api/tourist/reservations/applications/get",
  // TOURIST_RESERVATIONS_FIND: "/api/tourist/reservations/find",
  // TOURIST_RESERVATIONS_APPLICATIONS_FINDORCREATE:
  //   "/api/tourist/reservations/applications/find_or_create",
  // TOURIST_RESERVATIONS_APPLICATIONS_GUESTS:
  //   "/api/tourist/reservations/applications/guests",
  // TOURIST_RESERVATIONS_APPLICATIONS_GUESTS_IMAGE:
  //   "/api/tourist/reservations/applications/guests/image/extract",
  // TOURIST_RESERVATIONS_APPLICATIONS_CHECKIN:
  //   "/api/tourist/reservations/applications/checkin",

  TRANSLATION: "/api/translation",
  // USER_UPDATE: "/api/user/update",
  USER_GET: "/api/user/get",

  USER_ROAT_UPDATE: "/api/user/roat/update",

  // MANAGER_RESERVATIONS_APPLICATIONS_FINDBYRESERVATION:
  //   "/api/manager/reservations/applications/find_by_reservation",
  // MANAGER_RESERVATIONS_APPLICATIONS_MODIFICATIONS:
  //   "/api/manager/reservations/applications/modifications",
  // MANAGER_RESERVATIONS_APPLICATIONS_MODIFICATION_APPLY:
  //   "/api/manager/reservations/applications/modification/apply",
  // MANAGER_RESERVATIONINFO: "/api/manager/reservationinfo",
  // MANAGER_RESERVATIONINFO_UPDATE: "/api/admin/reservationinfo/update",
  MANAGER_REGISTER_BATCH: "/api/manager/register/batch",
  MANAGER_V2_RESERVATIONS_GET: "/api/v2/manager/reservations/get",
  MANAGER_V2_RESERVATIONS_GETALL: "/api/v2/manager/reservations/getall",
  MANAGER_V2_RESERVATIONS_INFO_GETALL: "/api/v2/manager/reservations/info/getall",
  MANAGER_V2_RESERVATIONS_SAVE: "/api/v2/manager/reservations/save",
  MANAGER_V2_RESERVATIONS_APPLICATIONS_SAVE: "/api/v2/manager/reservation_applications/save",
  MANAGER_V2_GUESTS_SAVE: "/api/v2/manager/guests/save",
  // MANAGER_V2_RESERVATIONS_CANCEL: "/api/v2/manager/reservations/cancel",
  MANAGER_V2_USER_DATA_SAVE: "/api/v2/manager/user_data_save",
  MANAGER_V2_USER_PASSWORD_SAVE: "/api/v2/manager/user_password_save",
  // MANAGER_V2_RESERVATIONS_DEPOSIT: "/api/v2/manager/reservations/deposit",

  TOURIST_V2_RESERVATION_APPLICATIONS_GET: '/api/v2/tourist/reservation_applications/get',
  TOURIST_V2_RESERVATIONS_GET: '/api/v2/tourist/reservations/get',
  // TOURIST_V2_RESERVATION_APPLICATIONS_SAVE: '/api/v2/tourist/reservation_applications/save',
  TOURIST_V2_RESERVATION_APPLICATIONS_FIND_OR_CREATE: '/api/v2/tourist/reservation_applications/find_or_create',
  TOURIST_V2_GUESTS_SAVE: '/api/v2/tourist/guests/save',
  TOURIST_V2_GUESTS_IMAGES_EXTRACT: '/api/v2/guests/images/extract',
  TOURIST_V2_CHECKING: '/api/v2/tourist/checkin',
  TOURIST_V2_RESERVATION_APPLICATION_INFO_GET: '/api/v2/tourist/reservation_application_info/get',


  TOURIST_RESERVATIONS_APPLICATIONS_GUESTS_IMAGES_EXTRACT:
    "/api/tourist/reservations/applications/guests/images/extract",

  ADMIN_CUSTOMERS_GETALL: "/api/admin/customers",
  ADMIN_RESERVATIONS_BY_CUSTOMER_ID: "/api/admin/reservations",
  ADMIN_RESERVATIONAPPLICATIONS_BY_ROOMCODE:
    "/api/admin/reservationapplications",
  ADMIN_CUSTOMERS_DELETE_BY_CUSTOMER_CODE: "/api/admin/customers/delete",
  ADMIN_RESERVATIONS_DELETE_BY_ROOM_CODE: "/api/admin/reservations/delete",
  ADMIN_RESERVATIONAPPLICATIONS_DELETE_BY_ID: "/api/admin/reservationapplications/delete",
  ADMIN_RESERVATIONAPPLICATIONS_UPDATE: "/api/admin/reservationapplications/update",

  ADMIN_V2_MANAGERS_GETALL: "/api/v2/admin/managers_getall",
  ADMIN_V2_RESERVATION_INFO_GETALL: "/api/v2/admin/reservations_info_getall",
  ADMIN_V2_RESERVATION_INFO_GETALL2:  "/api/v2/admin/reservations_info_getall2",
  ADMIN_V2_RESERVATION_APPLICATION_INFO_GETALL: "/api/v2/admin/reservation_application_info_getall",
  ADMIN_V2_USERS_SAVE: "/api/v2/admin/users/save",
  ADMIN_V2_RESERVATIONS_SAVE: "/api/v2/admin/reservations/save",
  ADMIN_V2_RESERVATION_APPLICATIONS_SAVE: "/api/v2/admin/resrevation_applications/save",
  ADMIN_V2_GUESTS_SAVE: "/api/v2/admin/guests/save",
  ADMIN_V2_MANAGER_INFOS_GETALL: "/api/v2/admin/manager_infos_getall",
  ADMIN_V2_RESERVATIONS_GETALL: "/api/v2/admin/reservations/getall",
  // ADMIN_V2_RESERVATIONS_SAVE: "/api/v2/admin/reservations/save",
};


interface IResponseResult {
    status: number | null;
    data: any;
}

export class RequestManager {
    baseUrl: string | null = null;
    email: string | null = null;
    password: string | null = null;
    accessToken: string | null = null;
    refreshToken: string | null = null;
    debug: boolean = false;
    onAnauthorized: (() => void) | null = null;
    constructor(baseUrl: string, debug: boolean=false) {
      this.baseUrl = baseUrl;
  
      this.email = null;
      this.password = null;
  
      this.accessToken = null;
      this.refreshToken = null;
  
      this.debug = debug;
      this.onAnauthorized = null;
    }
  
    setBaseUrl(url: string) {
      this.baseUrl = url;
    }
  
    async login(email: string, password: string) {
      this.logout_();
  
      this.email = email.trim();
      this.password = password.trim();
  
      const res = await this._authenticate();
  
      return res;
    }
  
    async register(
        email: string,
        password: string,
        firstname: string,
        lastname: string,
        phone: string
    ) {
      this.logout_();
  
      const creds = {
        firstname: firstname.trim(),
        lastname: lastname.trim(),
        email: email.trim(),
        phone: phone.trim(),
        password: password.trim(),
      };
      let result = await this.postJson(API_URLS.REGISTER, creds, false);
      return result;
    }
    logout_() {
      this.email = null;
      this.password = null;
  
      this.accessToken = null;
      this.refreshToken = null;
    }
    async logout() {
      const result = await this.postJson(API_URLS.LOGOUT, {});
      this.logout_();
      return result;
    }
    isAuthorized() {
      return this.accessToken !== null;
    }
    async requestLanguage(lang: string) {
      const res = await this.postJson(API_URLS.TRANSLATION, { language: lang }, false);
      if (res.status == 200) {
        const textInfos = res.data;
        return textInfos.reduce((res: {[key: string]: string}, info: {[key: string]: string}) => {
          res[info.key] = info[lang];
          return res;
        }, {});
      }
      return null;
    }
  
    async getJson(url: string, addAuth: boolean=true) {
      const fullUrl = `${this.baseUrl}${url}`;
      const options = {
        method: "GET",
      };
      const res = await this._sendRequestWithRefresh(fullUrl, options, addAuth);
      return res;
    }
  
    async postJson(url: string, bodyObj: any, addAuth: boolean = true) {
      const fullUrl = `${this.baseUrl}${url}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(bodyObj),
      };
      const res = await this._sendRequestWithRefresh(fullUrl, options, addAuth);
      return res;
    }
  
    async deleteJson(url: string, bodyObj: any) {
      const fullUrl = `${this.baseUrl}${url}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(bodyObj),
      };
  
      const res = await this._sendRequestWithRefresh(fullUrl, options, true);
      return res;
    }
  
    async uploadMultipart_(
      url: string,
      files: any,
      headers: any = null,
      keyValueDict: {[key:string]: any} = {}
    ) {
      const bodyData = new FormData();
      files.forEach((file: any, i: number) => {
        bodyData.append(`file${i}`, file);
      });
      Object.keys(keyValueDict).forEach((key) => {
        bodyData.append(key, keyValueDict[key]);
      });
      const options = {
        method: "POST",
        headers: headers,
        body: bodyData,
      }
      if (this.debug) {
        console.log("url: ", url);
        console.log("options: ", options);
      }
    
      const resp = await fetch(url, options);
      const result: {status: number, body: any} = {
        status: resp.status,
        body: null, // FIXME: was body
      };
      try {
        result.body = await resp.text();
      } catch (e) {
        console.log(e);
      }
      if (this.debug) {
        console.log("result: ", result);
      }
      return result;
    };
  
    async uploadMultipart(url: string, files: any, params={}, auth=true) {
      if (auth && (!this.accessToken || !this.refreshToken)) {
        this.onAnauthorized && this.onAnauthorized();
        return { status: 401, data: null };
      }
      const uploadUrl = `${this.baseUrl}${url}`;
      let headers = {};
      if (auth) {
        headers = {
          Authorization: "Bearer " + this.accessToken,
        };
      }
  
      let resp = await this.uploadMultipart_(uploadUrl, files, headers, params);
  
      const result: IResponseResult = { status: null, data: null };
      result.status = resp.status;
  
      result.data = resp.body;
      if (resp.status === 200 || resp.status === 201) {
        result.data = JSON.parse(resp.body);
      } else if (resp.status === 401) {
        await this._refresh();
        headers = {
          Authorization: "Bearer " + this.accessToken,
        };
        resp = await this.uploadMultipart_(uploadUrl, files, headers, params);
        result.status = resp.status;
        result.data = JSON.parse(resp.body);
      } else {
      }
      return result;
    }
  
    async _sendRequest(url: string, options: any, addAuth: boolean = true) {
      
      if (addAuth) {
        this._addAuthHeader(options);
      }
      if (this.debug) {
        console.log(`url: ${url}`);
        console.log("options:", options);
      }
  
      const result: IResponseResult = { status: null, data: null };
      try {
        const response = await fetch(url, options);
        result.status = response.status;
        result.data = await response.json();
      } catch (e) {
        console.error(e);
      }
      if (this.debug) {
        console.log("result: ", result);
      }
      return result;
    }
  
    async _sendRequestWithRefresh(url: string, options: any, addAuth: boolean=true) {
      if (addAuth && (!this.accessToken || !this.refreshToken)) {
        this.onAnauthorized && this.onAnauthorized();
        return { status: 401, data: null };
      }
      let resp = await this._sendRequest(url, options, addAuth);
      if (resp.status === 401) {
        let resp_refresh = await this._refresh();
        if (resp_refresh.status !== 200) {
          this.onAnauthorized && this.onAnauthorized();
          return resp;
        }
        resp = await this._sendRequest(url, options, true);
      }
      return resp;
    }
  
    _addAuthHeader(options: any) {
      if (this.accessToken) {
        if (!options.headers) {
          options["headers"] = {};
        }
        options.headers["Authorization"] = "Bearer " + this.accessToken;
        return true;
      }
      return false;
    }
  
    async _authenticate() {
      const creds = {
        username: this.email,
        password: this.password,
      };
      let result = await this.postJson(API_URLS.LOGIN, creds, false);
      if (result.status === 200) {
        const data = result.data;
        this.accessToken = data.token;
        this.refreshToken = data.refresh_token;
      }
      return result;
    }
  
    async _refresh() {
      // console.log("_refresh");
      const options = {
        method: "GET",
        headers: {
          "X-Refresh-Token": this.refreshToken,
        },
      };
      const fullUrl = `${this.baseUrl}${API_URLS.REFRESH}`;
      let result = await this._sendRequest(fullUrl, options, false);
      // console.log("refresh_result");
      // console.log(result);
      if (result.status === 200) {
        const data = result.data;
        this.accessToken = data.token;
        this.refreshToken = data.refresh_token;
        return { status: result.status, data };
      }
      return { status: result.status };
    }
  }
  