import React, { useEffect, useState } from "react";
import { FileText, User, Calendar, Users, Building } from "lucide-react";
import { InvoiceData } from "../types";

export interface IFormInitData {
  customerName: string;
  email: string;
  adults: string | number;
  nights: string | number;
  checkIn: string;
  checkOut: string;
  address: string;
}
const defaultFormInitData: IFormInitData = {
  customerName: "",
  email: "",
  adults: "",
  nights: "",
  checkIn: "",
  checkOut: "",
  address: "",
};

interface Props {
  onSubmit: (data: InvoiceData) => void;
  formInitData?: IFormInitData | null;
}

export function InvoiceForm({ onSubmit, formInitData }: Props) {
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [adults, setAdults] = useState("");
  const [nights, setNights] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    const { customerName, email, adults, nights, checkIn, checkOut, address } =
      formInitData ?? defaultFormInitData;

    setCustomerName(customerName);
    setEmail(email);
    setAdults("" + adults);
    setNights("" + nights);
    setCheckIn(checkIn);
    setCheckOut(checkOut);
    setAddress(address);
  }, [formInitData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    onSubmit({
      id: new Date().getTime().toString(),
      customerName: formData.get("customerName") as string,
      email: formData.get("email") as string,
      adults: parseInt(formData.get("adults") as string),
      nights: parseInt(formData.get("nights") as string),
      checkIn: formData.get("checkIn") as string,
      checkOut: formData.get("checkOut") as string,
      address: formData.get("address") as string,
      vatNumber: formData.get("vatNumber") as string,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <User className="w-4 h-4" />
            Customer Name
          </label>
          <input
            type="text"
            name="customerName"
            required
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <FileText className="w-4 h-4" />
            Email
          </label>
          <input
            type="email"
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <Users className="w-4 h-4" />
            Number of Adults
          </label>
          <input
            type="number"
            name="adults"
            min="1"
            required
            value={adults}
            onChange={(e) => setAdults(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <Calendar className="w-4 h-4" />
            Number of Nights
          </label>
          <input
            type="number"
            name="nights"
            min="1"
            required
            value={nights}
            onChange={(e) => setNights(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <Calendar className="w-4 h-4" />
            Check-in Date
          </label>
          <input
            type="date"
            name="checkIn"
            required
            value={checkIn}
            onChange={(e) => setCheckIn(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <Calendar className="w-4 h-4" />
            Check-out Date
          </label>
          <input
            type="date"
            name="checkOut"
            required
            value={checkOut}
            onChange={(e) => setCheckOut(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2 md:col-span-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <Building className="w-4 h-4" />
            Address
          </label>
          <textarea
            name="address"
            required
            rows={3}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>

        <div className="space-y-2 md:col-span-2">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <FileText className="w-4 h-4" />
            VAT Number (optional)
          </label>
          <input
            type="text"
            name="vatNumber"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      <button
        type="submit"
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
      >
        Generate Invoice
      </button>
    </form>
  );
}
