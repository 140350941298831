import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../utils/AppContext";
import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";
import { AccomodationEdit } from "../common/AccomodationEdit";

export function AdminAccomodations() {
  const {
    requestManager,
    user,
    accomodationId,
    setAccomodationId,
    getText,
  } = useContext(AppContext);

  const [accomodations, setAccomodations] = useState<any[]>([]);
  const [accomodationsRef, setAccomodationsRef] = useState<any[]>([]);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const inProgress = useRef(false);

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  useEffect(() => {
    if (accomodationId !== null) {
      setAccomodationId(null);
    }
    refreshAccomodations();
  }, []);

  const loadAccomodations = async () => {
    setLoading(true);
    const data = {};
    const res = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATIONS_GETALL,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      const accs = res.data;
      setAccomodations(accs);
      setAccomodationsRef(accs.map((g: any) => ({ ...g })));
    } else {
      setModalText(getText("m_res_app_error"));
    }
  };

  const refreshAccomodations = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await loadAccomodations();
    inProgress.current = false;
  };

  const updateAccomodation = (idx: number, value: any) => {
    const newAccomodations = [
      ...accomodations.slice(0, idx),
      value,
      ...accomodations.slice(idx + 1),
    ];
    setAccomodations(newAccomodations);
  };

  const deleteAccomodation = async (idx: number) => {
    setLoading(true);
    const data = {
      id: accomodations[idx]["id"],
      valid: false,
    };
    let res = { status: 200 };
    if (data.id) {
      res = await requestManager.postJson(
        API_URLS.ADMIN_V2_RESERVATIONS_SAVE,
        data
      );
    }
    setLoading(false);
    if (res.status === 200) {
      setAccomodations([
        ...accomodations.slice(0, idx),
        ...accomodations.slice(idx + 1),
      ]);
      setAccomodationsRef([
        ...accomodationsRef.slice(0, idx),
        ...accomodationsRef.slice(idx + 1),
      ]);
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const prepareAccomodation = (data: any) => {
    const res = {
      id: data.id,
      roat_number: data.roat_number.trim(),
      address: data.address.trim(),
      postal_code_city: data.postal_code_city.trim(),
    };
    return res;
  };

  const saveAccomodation = async (idx: number) => {
    setLoading(true);
    const data = prepareAccomodation(accomodations[idx]);
    const res = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATIONS_SAVE,
      data
    );
    if (res.status === 200) {
      const savedAccomodation = res.data;
      setAccomodations([
        ...accomodations.slice(0, idx),
        { ...savedAccomodation },
        ...accomodations.slice(idx + 1),
      ]);
      setAccomodationsRef([
        ...accomodationsRef.slice(0, idx),
        { ...savedAccomodation },
        ...accomodationsRef.slice(idx + 1),
      ]);
    } else if (res.status === 409) {
      setModalText(getText("m_res_app_exist"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
    setLoading(false);
  };

  const checkSaved = (idx: number) => {
    const g = accomodations[idx];
    const gf = accomodationsRef[idx];
    if (!g || !gf) return false;
    for (const key of Object.keys(gf)) {
      if (g[key] !== gf[key]) {
        return false;
      }
    }
    return true;
  };

  const accomodationsSaved = accomodations.map((a, i) => checkSaved(i));

  return (
    <div className="page-content">
      <div style={{ display: "flex", gap: "40px", padding: "20px 0" }}>
        {accomodations.map((accomodation, idx) => {
          return (
            <AccomodationEdit
              key={accomodation["id"]}
              idx={idx}
              accomodation={accomodation}
              setAccomodation={(val) => updateAccomodation(idx, val)}
              onSave={() => saveAccomodation(idx)}
              onDelete={() => deleteAccomodation(idx)}
              saved={accomodationsSaved[idx]}
              onBadSave={() => setModalText(getText("m_some_fields_invalid"))}
            />
          );
        })}
      </div>

      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
}
