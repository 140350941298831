import React, { useContext, useState } from "react";
import { notEmptyValidator, validateForm } from "../../utils/FormManager";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { IReservation } from "../common/types";

const name2validators = {
  roat_number: [notEmptyValidator()],
  address: [notEmptyValidator()],
  postal_code_city: [notEmptyValidator()],
};

interface IAccomodationEditProps {
  idx: number;
  accomodation: IReservation;
  setAccomodation: (x: any) => void;
  onSave: () => void;
  onDelete: () => void;
  saved: boolean;
  onBadSave?: (() => void) | null;
}

export function AccomodationEdit({
  idx,
  accomodation,
  setAccomodation,
  onSave,
  onDelete,
  saved,
  onBadSave = null,
}: IAccomodationEditProps) {
  const { getText } = useContext(AppContext);

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  const [show, setShow] = useState<boolean>(false);

  const roat_number = accomodation["roat_number"];
  const address = accomodation["address"];
  const postal_code_city = accomodation["postal_code_city"];
  const room_code = accomodation["room_code"];

  const handleSave = async () => {
    const invalidFields = validateForm(accomodation, name2validators);
    if (invalidFields.length === 0) {
      onSave();
    } else {
      const parts = [];
      for (const nameMsg of invalidFields) {
        parts.push(nameMsg.join(": "));
      }
      if (onBadSave) {
        onBadSave();
      }
    }
  };

  const handleToggleShow = () => {
    setShow((prev) => !prev);
  };

  const renderBody = () => {
    return ( <div className="control__wrap">
      <div style={{width:"100%", position: "relative"}}>
        <div
          className="btn"
          style={{
            position: "absolute", right: 0, top: 0, 
            minWidth: "auto",
            alignSelf: "center",
            justifySelf: "flex-end",
            fontSize: "14px",
          }}
          onClick={() => onDelete()}
        >
          <img
            src="/images/delete.svg"
            alt="delete"
            className="filter_gray"
            style={{ width: "1.2em" }}
          />
        </div>
      </div>
      <div className="form__control">
        <MyInput
          type="text"
          label={getText("l_roat_number")}
          placeholder={getText("ph_roat_number")}
          value={roat_number}
          setValue={(v) =>
            setAccomodation({ ...accomodation, roat_number: v })
          }
          validators={name2validators["roat_number"]}
          messages={formMessages["roat_number"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, roat_number: v })
          }
        />
      </div>
      <div className="form__control">
        <MyInput
          type="text"
          label={getText("l_address")}
          placeholder={getText("ph_address")}
          value={address}
          setValue={(v) => setAccomodation({ ...accomodation, address: v })}
          validators={name2validators["address"]}
          messages={formMessages["address"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, address: v })
          }
        />
      </div>

      <div className="form__control">
        <MyInput
          type="text"
          label={getText("l_postal_code_city")}
          placeholder={getText("ph_postal_code_city")}
          value={postal_code_city}
          setValue={(v) =>
            setAccomodation({ ...accomodation, postal_code_city: v })
          }
          validators={name2validators["postal_code_city"]}
          messages={formMessages["postal_code_city"] || []}
          setMessages={(v) =>
            setFormMessages({ ...formMessages, postal_code_city: v })
          }
        />
      </div>

      <div className="form__control" style={{ width: "100%" }}>
        <label className="control-label">{getText("l_room_code")}</label>
        <div className="card_outline">
          <p
            className="input_outline tx_secondary"
            style={{ width: "50%", minWidth: "240px" }}
          >
            {room_code ? room_code : getText("ph_no_room_code")}
          </p>
          <div className="tx_secondary">{getText("t_room_code")}</div>
        </div>
      </div>

      {!saved && (
        <div className="control_center">
          <div className="btn bg_primary" onClick={() => handleSave()}>
            {getText("btn_save")}
          </div>
        </div>
      )}
    </div>);
  };

  return (
    <div key={accomodation["id"]} className="form-container w520_ifw">
      <div className="form-card">
        <div
          className="card__header form__control"
          style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-between", cursor:"pointer"}}
          onClick={() => handleToggleShow()}
        >
          <h3>{address}</h3>
          <div
            className="btn"
            style={{
              minWidth: "auto",
              padding: "0",
              alignSelf: "center",
              justifySelf: "flex-end",
              fontSize: "14px",
            }}
            
          >
            <img
              src={
                show ? "/images/triangle-up.svg" : "/images/triangle-down.svg"
              }
              alt="expand"
              style={{ width: "1.2em" }}
            />
          </div>
        </div>
        {show && renderBody()}
      </div>
    </div>
  );
}
