import React from 'react';
import { Calculator } from 'lucide-react';
import { CalculationResult } from '../types';

interface Props {
  adults: number;
  nights: number;
  onCalculate: (result: CalculationResult) => void;
}

const TAX_RATE = 2.11;

export function InvoiceCalculator({ adults, nights, onCalculate }: Props) {
  const calculate = () => {
    const subtotal = adults * nights * TAX_RATE;
    const total = subtotal;
    
    onCalculate({
      subtotal,
      total,
      taxRate: TAX_RATE
    });
  };

  return (
    <div className="flex items-center gap-4 p-4 bg-blue-50 rounded-lg">
      <Calculator className="w-5 h-5 text-blue-600" />
      <div className="flex-1">
        <p className="text-sm text-blue-600">
          Calculation: {adults} adults × {nights} nights × €{TAX_RATE.toFixed(2)}
        </p>
      </div>
      <button
        onClick={calculate}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
      >
        Calculate
      </button>
    </div>
  );
}