import React, { useContext } from "react";
import { AppContext } from "../../utils/AppContext";

export function Legislation() {
  const { getText } = useContext(AppContext);

  return (
    <div className="page-content">
      <div style={{ display: "flex", flexWrap: "wrap", paddingTop: "20px" }}>
        <div className="form-card" style={{ width: "100%" }}>
          <h3 className="content-header">
            {getText("h_legislation")}
          </h3>
          <div className="content-list content-list_padding-left">
            <div>
              <div>Tourism website</div>
              <a href="https://www.turisme.ad/roat" style={{wordWrap:"break-word"}}>
                https://www.turisme.ad/roat
              </a>
            </div>
            <div>
              <div>Tourists information</div>
              <a href="https://www.turisme.ad/images/stories/pdf/ROAT_InformacioClients.pdf" style={{wordWrap:"break-word"}}>
                https://www.turisme.ad/images/stories/pdf/ROAT_InformacioClients.pdf
              </a>
            </div>
            <div>
              <div>Law 16/2017, of July 13, general tourist accommodation.</div>
              <a href="https://www.bopa.ad/bopa/029052/Pagines/CGL20170728_09_50_07.aspx" style={{wordWrap:"break-word"}}>
                https://www.bopa.ad/bopa/029052/Pagines/CGL20170728_09_50_07.aspx
              </a>
            </div>
            <div>
              <div>
                Decree of 25-04-2018 approving the Regulations for the Occupancy
                Register of Tourist Accommodations (ROAT)
              </div>
              <a href="https://www.bopa.ad/bopa/030025/Pagines/GR20180426_16_10_04.aspx" style={{wordWrap:"break-word"}}>
                https://www.bopa.ad/bopa/030025/Pagines/GR20180426_16_10_04.aspx
              </a>
            </div>
            <div>
              <div>
                Decree amending the Regulations of the Employment Register of
                Tourist Accommodation (ROAT)
              </div>
              <a href="https://www.turisme.ad/images/stories/pdf/GR20190411_11_03_09.pdf" style={{wordWrap:"break-word"}}>
                https://www.turisme.ad/images/stories/pdf/GR20190411_11_03_09.pdf
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
