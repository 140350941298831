export const validateForm = (
  data: { [key: string]: any },
  name2validators: { [key: string]: any }
): [string,string][] => {
  const invalidFields:[string,string][] = [];
  for (let name of Object.keys(name2validators)) {
    const validators = name2validators[name];
    for (let validator of validators) {
      const value = data[name];
      const res = validator(value);
      if (!res.status) {
        invalidFields.push([name, res.message]);
        break;
      }
    }
  }
  return invalidFields;
};

export const numberInRangeValidator = (min:number, max:number) => (val:any) => {
  const num = +val;
  if (!isFinite(num)) {
    return { status: false, message: "must be a number" };
  }
  if (num < min || num > max) {
    return {
      status: false,
      message: `value must be in range: [${min}, ${max}]`,
    };
  }
  return { status: true };
};

export const notEmptyValidator = () => (val:any) => {
  if (val === "") {
    return { status: false, message: `value must not be empty` };
  }
  return { status: true };
};
export const lengthValidator =
  (min:number = 0, max:number = 255) =>
  (val:any) => {
    if (val.length < min) {
      return {
        status: false,
        message: `field should have at least ${min} characters`,
      };
    }
    if (val.length > max) {
      return {
        status: false,
        message: `field should have no more than ${max}`,
      };
    }
    return { status: true };
  };
export const dateObjectValidator = () => (val:any) => {
  if (
    val.day === null ||
    val.day === undefined ||
    val.month === null ||
    val.month === undefined ||
    val.year === null ||
    val.year === undefined
  ) {
    return { status: false, message: `value must not be empty` };
  }
  return { status: true };
};
export const isNotNull = () => (val:any) => {
  if (val === null || val === undefined) {
    return { status: false, message: `value must not be empty` };
  }
  return { status: true };
};
export const inDictValidator =
  (dict: {[key: string]: any}, canBeEmpty = false, message = `value not found`) =>
  (val:any) => {
    if (val === "" && canBeEmpty) {
      return { status: true };
    }
    if (!(val in dict)) {
      return { status: false, message };
    }
    return { status: true };
  };

export const isValidDateStr =
  (message = `invalid date`) =>
  (val:any) => {
    // console.log(val);
    if (
      val !== null &&
      val !== undefined &&
      /^\d{2,4}-\d{2}-\d{2}$/.test(val) &&
      isFinite(new Date(val) as any)
    ) {
      return { status: true };
    } else {
      return { status: false, message };
    }
  };
