import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyToggle } from "../common/MyToggle";
import { MyInput } from "../common/MyInput";
import {
  IGuest,
  IHeaderInfo,
  IReservationApplication,
  IReservationInfo,
} from "../common/types";
import { format_date, makeDeepCopy, sortRows } from "../../utils/helpers";
import { MyRadioButton } from "../common/MyButton";
import { MyDateInput } from "../common/MyDateInput";

interface IGuestTableProps {
  datas: IGuest[];
  saveDatas: (datas: IGuest[]) => void;
  selected: IGuest | null;
  onSelect: (data: IGuest) => void;
  onDelete: () => void;
}

const headerInfos: IHeaderInfo[] = [
  { label: "Edit", key: "" },
  { label: "First name", key: "firstname" },
  { label: "Last name", key: "lastname" },
  { label: "Citizenship", key: "citizenship" },
  { label: "Residency", key: "residency" },
  { label: "Place of birth", key: "place_of_birth" },
  { label: "Birth date", key: "birth_date" },
  { label: "Visualize", key: "" },
];

export const GuestTable: React.FC<IGuestTableProps> = ({
  datas,
  saveDatas,
  selected,
  onSelect,
  onDelete,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [rowsEdit, setRowsEdit] = useState<IGuest[]>([]);
  const [rowsSorted, setRowsSorted] = useState<IGuest[]>([]);

  useEffect(() => {
    setRowsEdit(makeDeepCopy(datas));
  }, [datas]);

  useEffect(() => {
    const rows = sortRows(rowsEdit, sortKey, sortAsc);
    setRowsSorted(rows);
  }, [rowsEdit, sortKey, sortAsc]);

  const handleChangeSortKey = (header: string | null) => {
    let asc = sortAsc;
    if (sortKey === header) {
      asc = !asc;
    } else {
      asc = false;
      setSortKey(header);
    }
    setSortAsc(asc);
  };

  const renderHeader = () => {
    return (
      <tr>
        {headerInfos.map((hinfo, hi) => {
          const active_ = sortKey === hinfo.key;
          return (
            <th key={hi} onClick={() => handleChangeSortKey(hinfo.key)}>
              <div style={{ display: "flex", minWidth: "160px" }}>
                {hinfo.label}&nbsp;
                <MyToggle active={active_} />
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const updateRow = (newValue: IGuest) => {
    setRowsEdit((rows) =>
      rows.map((row) => (row.id !== newValue.id ? row : newValue))
    );
  };

  const renderRows = () => {
    return rowsSorted.map((row) => {
      return (
        <tr key={row.id}>
          <td>
            <div>
              <MyRadioButton checked={false} />
            </div>
          </td>
          <td>
            <div>{row.firstname}</div>
          </td>
          <td>
            <div>{row.lastname}</div>
          </td>
          <td>
            <div>{row.citizenship}</div>
          </td>
          <td>
            <div>{row.residency}</div>
          </td>
          <td>
            <div>{row.place_of_birth}</div>
          </td>
          <td>
            <div>{row.birth_date}</div>
          </td>

          <td onClick={() => onSelect(row)}>
            <MyRadioButton checked={selected?.id === row.id} />
          </td>
        </tr>
      );
    });
  };

  const renderRowsEdit = () => {
    return rowsSorted.map((row) => {
      return (
        <tr key={row.id}>
          <td></td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.firstname}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    firstname: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.lastname}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    lastname: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.citizenship}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    citizenship: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.residency}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    residency: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.place_of_birth}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    place_of_birth: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyDateInput
                value={row.birth_date}
                setValue={(v) =>
                  updateRow({
                    ...row,
                    birth_date: v,
                  })
                }
              />
            </div>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  const checkModified = () => {
    return JSON.stringify(datas) !== JSON.stringify(rowsEdit);
  };

  const modified = checkModified();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "32px" }}>
        <div className="btn btn_bg" onClick={() => setEditMode(true)}>
          <img
            src="/images/edit.svg"
            className="filter_gray"
            style={{ width: "1.5em" }}
            alt="edit"
          />
        </div>
        <div className="btn btn-with-image" onClick={() => onDelete()}>
          <img
            src="/images/delete2.svg"
            className="filter_gray"
            style={{ width: "1.2em" }}
            alt="delete2"
          />
        </div>
      </div>
      <div className="form-card card_scroll">
        <table>
          <thead>{renderHeader()}</thead>
          <tbody>{editMode ? renderRowsEdit() : renderRows()}</tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "32px" }}>
        {editMode && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div
              className="btn bg_secondary"
              onClick={() => setEditMode(false)}
            >
              Stop Editing
            </div>
          </div>
        )}
        {modified && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div className="btn bg_primary" onClick={() => saveDatas(rowsEdit)}>
              Save
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
