import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../utils/AppContext";
import {
  validateForm,
  isValidDateStr,
  notEmptyValidator,
  inDictValidator,
} from "../../utils/FormManager";
import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { COUNTRY_TO_IDX } from "../../utils/constants";
import { WEB_ROUTERS } from "../../utils/routes";
import { GuestSteps } from "./GuestSteps";
import { makeDeepCopy } from "../../utils/helpers";
import { MySelect } from "../common/MySelect";
import { GuestStepsEdit } from "./GuestStepsEdit";
import { IGuest } from "../common/types";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  place_of_birth: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")], // FIXME: translate this
  citizenship: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  residency: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  birth_date: [isValidDateStr()],
};

const guestCountOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
];

function dateToString(date: Date | null | undefined) {
  if (!date) return "";
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join("-");
}

export function GuestStepsLastPage() {
  const {
    requestManager,
    reservationApplication,
    setReservationApplication,
    getText,
  } = useContext(AppContext);

  // const [reservationApplication, setReservationApplication] = useState<IReservationApplication>();
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [guestsRef, setGuestsRef] = useState<IGuest[]>([]);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalConfirmCallback, setModalConfirmCallback] = useState<any>(null);
  const [modalConfirmText, setModalConfirmText] = useState("");

  const [guestCount, setGuestCount] = useState<number>(1);
  const [modalCounter, setModalCounter] = useState<string | number | null>(
    null
  );

  const inProgress = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!reservationApplication) {
      navigate(WEB_ROUTERS.TOURIST_RESERVATION);
      return;
    }

    refreshReservation();
  }, []);


  const refreshReservation = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    const [guests_, guestsRef_] = await loadReservation();
    if (guests_ && guests_.length < guestCount /*&& readyToCheckin*/) {
      // TODO: add guest
      await addGuest(guests_, guestsRef_);
    }

    inProgress.current = false;
  };
  const handleGuestCountChange = async (count: string) => {
    // delete guests
    setGuestCount(+count);
    if (guests.length < +count /*&& readyToCheckin*/) {
      // TODO: add guest
      await addGuest();
    }
  };
  const loadReservation = async () => {
    setLoading(true);
    const data = {
      id: reservationApplication.id,
    };
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_RESERVATION_APPLICATIONS_GET,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      setReservationApplication(res.data);

      const activeGuests = res.data.guests ?? [];
      // console.log(activeGuests);
      const guests_ = activeGuests;
      const guestsRef_ = makeDeepCopy(activeGuests);
      setGuests(guests_);
      setGuestsRef(guestsRef_);
      return [guests_, guestsRef_];
    } else {
      setModalText(getText("m_res_app_error"));
      return [null, null];
    }
  };

  const updateGuest = (idx: number, value: any) => {
    const newGuests = [
      ...guests.slice(0, idx),
      value,
      ...guests.slice(idx + 1),
    ];
    setGuests(newGuests);
  };
  // const updateGuestRef = (idx: number, value: any) => {
  //   const newGuests = [
  //     ...guestsRef.slice(0, idx),
  //     value,
  //     ...guestsRef.slice(idx + 1),
  //   ];
  //   setGuestsRef(newGuests);
  // };

  const addGuest = async (guests_: any = null, guestsRef_: any = null) => {
    guests_ = guests_ ?? guests;
    guestsRef_ = guestsRef_ ?? guestsRef;
    if (guests.length >= 6) {
      setModalText(getText("m_guest_limit"));
      return;
    }
    setLoading(true);
    const data = {
      reservation_application_id: reservationApplication.id,
      citizenship: "",
      residency: "",
      valid: true,
      firstname: "",
      lastname: "",
      place_of_birth: "",
      birth_date: "",
    };
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_GUESTS_SAVE,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      const newGuest = res.data;
      setGuests([...guests_, { ...newGuest }]);
      setGuestsRef([...guestsRef_, { ...newGuest }]);
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };
  const deleteGuest = async (idx: number) => {
    setLoading(true);
    const guest = guests[idx];
    const data = {
      reservation_application_id: reservationApplication.id,
      id: guest["id"],
      valid: false,
    };
    const res = await requestManager.deleteJson(
      API_URLS.TOURIST_V2_GUESTS_SAVE,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      setGuests([...guests.slice(0, idx), ...guests.slice(idx + 1)]);
      setGuestsRef([...guestsRef.slice(0, idx), ...guestsRef.slice(idx + 1)]);
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };
  const prepareGuest = (guest: any) => {
    guest.firstname = guest.firstname.trim();
    guest.lastname = guest.lastname.trim();
    guest.birth_date = guest.birth_date.trim();
    guest.citizenship = guest.citizenship.trim();
    guest.residency = guest.residency.trim();
    guest.place_of_birth = guest.place_of_birth.trim();
    return guest;
  };
  const handleSaveGuest = (idx: number) => {
    // show modal
    setModalConfirmCallback(() => async () => {
      const [guests_, guestsRef_] = await saveGuest(idx);
      // console.log("readyToCheckin", readyToCheckin);
      if (guests_ && guests_.length < guestCount) {
        await addGuest(guests_, guestsRef_);
      }
    });
    setModalConfirmText(getText("m_photo_confirm"));
  };
  const saveGuest = async (idx: number) => {
    setLoading(true);
    const guest = {
      ...guests[idx],
      reservation_application_id: reservationApplication.id,
    };
    prepareGuest(guest);
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_GUESTS_SAVE,
      guest
    );
    let guests_ = null;
    let guestsRef_ = null;
    if (res.status === 200) {
      const savedGuest = res.data;
      // console.log("savedGuest", savedGuest);
      guests_ = [
        ...guests.slice(0, idx),
        { ...savedGuest },
        ...guests.slice(idx + 1),
      ];
      guestsRef_ = [
        ...guestsRef.slice(0, idx),
        { ...savedGuest },
        ...guestsRef.slice(idx + 1),
      ];
      setGuests(guests_);
      setGuestsRef(guestsRef_);
    } else if (res.status === 400) {
      setModalText(getText("m_some_fields_or_images_invalid"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
    setLoading(false);
    return [guests_, guestsRef_];
  };

  const checkSaved = (idx: number) => {
    const g = guests[idx] as any;
    const gf = guestsRef[idx] as any;
    // console.log("checkSaved",guests,guestsRef,idx);
    if (!g || !gf) return false;
    for (const key of Object.keys(gf)) {
      // console.log(key);
      if (Array.isArray(g[key])) {
        continue;
      } else if (g[key] instanceof Date) {
        const ds = dateToString(g[key]);
        const dfs = dateToString(gf[key]);
        if (ds !== dfs) {
          return false;
        }
      } else if (g[key] !== gf[key]) {
        return false;
      }
    }
    // console.log("saved");
    return true;
  };

  const onSend = async () => {
    if (guests.length !== guestsRef.length) {
      alert("Debug: guests.length !== guestsRef.length");
      return;
    }
    for (let i = 0; i < guests.length; i++) {
      if (!checkSaved(i)) {
        setModalText(getText("m_data_not_validated"));
        return;
      }

      const invalidFields = validateForm(guests[i], name2validators);
      if (invalidFields.length !== 0) {
        setModalText(getText("m_some_fields_invalid"));
        // alert("Debug: Some values are invalid or empty");
        return;
      }
    }
    setLoading(true);
    const data = { reservation_application_id: reservationApplication.id };
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_CHECKING,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      // setModalText("Checkin successull!");
      // setShowModalCheckin(true);
      // navigate(WEB_ROUTERS.TOURIST_RESERVATION);
      navigate(WEB_ROUTERS.TOURIST_PAYMENT, {state: data});
    } else if (res.status === 400) {
      setModalText(getText("m_some_fields_or_images_invalid"));
    } else {
      alert("DEBUG: " + JSON.stringify(res));
    }
  };

  const handleSend = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await onSend();
    inProgress.current = false;
  };
  const handleConfirm = () => {
    setModalConfirmCallback(() => () => handleSend()); // FIXME: Why should we pass function returning callback?
    setModalConfirmText(getText("m_checkin_confirm"));
  };

  const guestSaved = guests.map((g, i) => checkSaved(i));
  const guestHaveImages = guests.map((g, i) => g["id_images"].length > 0);

  const guestIsFull = (guest: any) => {
    if (
      guest.firstname &&
      guest.lastname &&
      guest.birth_date &&
      guest.citizenship &&
      guest.residency &&
      guest.place_of_birth
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="page-content">
      <h3 className="header-tag">{getText("h_tourist_reservation")}</h3>
      <div className="form-container">
        <div className="step-container w520_ifw">
          <span className="step-number step-number_active">1</span>
          <div className="form-card">
            <h4 className="step-header">{getText("s_travellers_count")}</h4>
            <MySelect
              options={guestCountOptions}
              value={guestCount}
              onChange={handleGuestCountChange}
            />

            <h4>{getText("s_travellers_count_note")}</h4>
          </div>
        </div>

        {guests.map(
          (guest, idx) =>
            (!guestSaved[idx] ||
              !guestHaveImages[idx] ||
              !guestIsFull(guests[idx])) && (
              <GuestSteps
                idx={idx}
                guest={guest}
                setGuest={(g: any) => updateGuest(idx, g)}
                reservation_application_id={reservationApplication?.id}
                setLoading={(v: any) => setLoading(v)}
                onDelete={() => deleteGuest(idx)}
                onSave={() => handleSaveGuest(idx)}
                saved={guestSaved[idx]}
                onBadPhoto={() =>
                  setModalText(getText("m_image_extract_error"))
                }
                onBadSave={() => setModalText(getText("m_some_fields_invalid"))}
                onShowModal={(text: any) => setModalText(text)}
                onShowModalCounter={(c) => setModalCounter(c)}
              />
            )
        )}

        {guests.length < guestCount /*&& readyToCheckin*/ && (
          <div className="step-container w520_ifw">
            <div className="form-card" style={{ width: "100%" }}>
              <h4 className="step-header">{getText("s_newguest")}</h4>
              <div className="control__wrap">
                <div className="form__control">
                  <div className="btn bg_secondary " onClick={() => addGuest()}>
                    {getText("btn_new_guest")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {guests.length >= guestCount &&
          guestIsFull(guests[guests.length - 1]) && (
            <React.Fragment>
              <div className="step-container w520_ifw">
                <span className="step-number step-number_active">5</span>
                <div className="form-card">
                  <h4 className="step-header">{getText("s_recap")}</h4>
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                {/* {guests.map((guest, idx) => renderGuestSummary(idx))} */}
                {guests.map((guest, idx) => (
                  <GuestStepsEdit
                    idx={idx}
                    guest={guest}
                    setGuest={(g: any) => updateGuest(idx, g)}
                    onDelete={() => deleteGuest(idx)}
                    onSave={() => handleSaveGuest(idx)}
                    saved={guestSaved[idx]}
                    onBadSave={() =>
                      setModalText(getText("m_some_fields_invalid"))
                    }
                  />
                ))}
              </div>

              <div className="step-container w520_ifw">
                <span className="step-number">6</span>
                <div className="form-card" style={{ width: "100%" }}>
                  <h4 className="step-header">{getText("s_checkin")}</h4>
                  <div className="control__wrap">
                    {guests.length >= guestCount && (
                      <div className="form__control">
                        <div
                          className="btn bg_success "
                          onClick={() => handleConfirm()}
                        >
                          {getText("btn_checkin")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {modalConfirmCallback && (
        <MyModal>
          <div className="modal-text">{modalConfirmText}</div>
          <div
            className="btn bg_secondary"
            onClick={() => {
              setModalConfirmCallback(null);
              setModalConfirmText("");
            }}
          >
            {getText("btn_no")}
          </div>
          <div
            className="btn bg_success"
            onClick={() => {
              const callback = modalConfirmCallback;
              setModalConfirmCallback(null);
              setModalConfirmText("");
              callback();
            }}
          >
            {getText("btn_yes")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
      {modalCounter && (
        <MyModal>
          <div className="modal-text">{modalCounter}</div>
        </MyModal>
      )}
    </div>
  );
}
