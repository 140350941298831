import React, { useContext, useState, useRef } from "react";
import {
  isValidDateStr,
  notEmptyValidator,
  validateForm,
  inDictValidator,
} from "../../utils/FormManager";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { MyDateInput } from "../common/MyDateInput";
import { API_URLS } from "../../utils/RequestManager";
import { COUNTRY_TO_IDX, COUNTRY_ENGLISH } from "../../utils/constants";
import { MyFileInput } from "../common/MyFileInput";
import { MyAutocompleteInput } from "../common/MyAutocompleteInput";
import { MyRadioButtonLabeled } from "../common/MyButton";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  place_of_birth: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")], // FIXME: translate this
  citizenship: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  residency: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  birth_date: [isValidDateStr()],
};

const DOCUMENT_PASSPORT = "passport";
const DOCUMENT_IDENTITY_CARD = "identity card";

interface IGuestStepsProps {
  idx: number;
  guest: any;
  setGuest: (v: any) => void;
  reservation_application_id: number;
  setLoading: (v: boolean) => void;
  onDelete: () => void;
  onSave: () => void;
  saved: boolean;
  onBadPhoto?: () => void;
  onBadSave?: () => void;
  onShowModal?: (v: string) => void;
  onShowModalCounter?: (v: string | number | null) => void;
}

export const GuestSteps: React.FC<IGuestStepsProps> = ({
  idx,
  guest,
  setGuest,
  reservation_application_id,
  setLoading,
  onDelete,
  onSave,
  saved,
  onBadPhoto = null,
  onBadSave = null,
  onShowModal = null,
  onShowModalCounter = null,
}) => {
  const { requestManager, getText } = useContext(AppContext);
  const [documentType, setDocumentType] = useState("");
  const [images1, setImages1] = useState<any>(null);
  const [images2, setImages2] = useState<any>(null);

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});
  //   const [loading, setLoading] = useState(false);
  const [timedOut, setTimedOut] = useState<boolean>(false);
  const inProgress = useRef(false);
  const intervalId = useRef<number | null>(null);

  const guest_id = guest["id"];

  const sendImages = async () => {
    setLoading(true);

    const files = []; // FIXME:
    if (images1) {
      files.push(images1);
    }
    if (documentType === DOCUMENT_IDENTITY_CARD) {
      files.push(images2);
    }
    // console.log("files", files);
    if (!files.length) {
      onShowModal && onShowModal(getText("m_no_data_specified"));
      setLoading(false);
      return;
    }
    const guest_info: any = {};
    let new_id_images = [];
    const params = {
      reservation_application_id,
      guest_id,
    };
    const res = await requestManager.uploadMultipart(
      API_URLS.TOURIST_RESERVATIONS_APPLICATIONS_GUESTS_IMAGES_EXTRACT,
      files,
      params
    );
    // console.log(res);
    if (res.status === 200) {
      const imageDatas = res["data"];

      for (const imageData of imageDatas) {
        const extraction = imageData["extraction"];
        if (Object.keys(extraction).length > 0) {
          new_id_images.push(imageData["id_image"]);
        }
        for (const key of Object.keys(extraction)) {
          const val = extraction[key];
          if (val && !(key in guest_info)) {
            guest_info[key] = val;
          }
        }
      }
    } else if (res.status === 401) {
      onShowModal && onShowModal(getText("m_failed_authorize"));
      setLoading(false);
      return;
    }

    if (Object.keys(guest_info).length === 0 && onBadPhoto) {
      new_id_images = [];
      // console.log("bad photo because guest info empty")
      onBadPhoto();
    }
    setLoading(false);
    setGuest({
      ...guest,
      ...guest_info,
      id_images: new_id_images,
    });
  };

  const _clearTimer = () => {
    if (intervalId.current) {
      window.clearInterval(intervalId.current);
      intervalId.current = null;
    }
    onShowModalCounter && onShowModalCounter(null);
  };
  const _startTimer = () => {
    setTimedOut(false);
    _clearTimer();
    let counter = 10;
    onShowModalCounter && onShowModalCounter(counter);
    intervalId.current = window.setInterval(() => {
      // console.log("counter", counter);
      if (counter <= 0) {
        intervalId.current && window.clearInterval(intervalId.current);
        setTimedOut(true);
        onShowModalCounter && onShowModalCounter(null);
        setLoading(false);
        return;
      }
      onShowModalCounter && onShowModalCounter(counter - 1);
      counter--;
    }, 1000);
  };
  const handleSendImages = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    // start timer
    _startTimer();
    try {
      await sendImages();
    } catch {
      setLoading(false);
      setTimedOut(true);
      onShowModal && onShowModal(getText("m_image_extract_error"));
    }
    _clearTimer();

    inProgress.current = false;
  };

  const firstname = guest["firstname"];
  const lastname = guest["lastname"];
  const birth_date = guest["birth_date"] || "";
  const citizenship = guest["citizenship"];
  const residency = guest["residency"];
  const place_of_birth = guest["place_of_birth"];
  const id_images = guest["id_images"];
  // console.log(id_images);

  const haveValidImages = id_images?.length > 0;
  const haveImagesToExtract =
    (documentType === DOCUMENT_PASSPORT && images1 !== null) ||
    (documentType === DOCUMENT_IDENTITY_CARD &&
      images1 !== null &&
      images2 !== null);

  const renderImages = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="form__control" style={{ paddingTop: 0 }}>
            <h4 className="step-header" style={{ paddingTop: 0 }}>
              {getText("s_take_photo_recto")}
            </h4>
            <MyFileInput
              label={""}
              btn_label={getText("btn_photo_recto")}
              setValue={(v) => setImages1(v)}
            />
            {images1 && <p>{images1.name}</p>}

            {documentType === DOCUMENT_IDENTITY_CARD && images1 && (
              <div>
                <h4 className="step-header" style={{ paddingTop: 20 }}>
                  {getText("s_take_photo_verso")}
                </h4>
                <div className="form__control" style={{ paddingTop: 0 }}>
                  <MyFileInput
                    label={""}
                    btn_label={getText("btn_photo_verso")}
                    setValue={(v) => setImages2(v)}
                  />
                  {images2 && <p>{images2.name}</p>}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="example-img-container">
          <img
            src={"/images/mrz_example.png"}
            alt="example"
            className="example-img"
          />
          <h4>{getText("s_take_photo_note")}</h4>
        </div>
        {haveImagesToExtract && (
          <div className="control_center" style={{ paddingTop: 0 }}>
            <div className="btn bg_success" onClick={handleSendImages}>
              {getText("btn_read")}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const handleSave = async () => {
    const invalidFields = validateForm(guest, name2validators);
    if (invalidFields.length === 0) {
      onSave();
    } else {
      if (onBadSave) {
        onBadSave();
      }
    }
  };

  const renderGuestEdit = () => {
    const disabled = !haveValidImages && !timedOut;
    return (
      <div className="control__wrap">
        <div className="form__control">
          <MyInput
            type="text"
            label={getText("t_firstname")}
            placeholder={getText("ph_firstname")}
            value={firstname}
            setValue={(v) => setGuest({ ...guest, firstname: v })}
            validators={name2validators["firstname"]}
            messages={formMessages["firstname"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, firstname: v })
            }
            disabled={disabled}
          />
        </div>
        <div className="form__control">
          <MyInput
            type="text"
            label={getText("t_lastname")}
            placeholder={getText("ph_lastname")}
            value={lastname}
            setValue={(v) => setGuest({ ...guest, lastname: v })}
            validators={name2validators["lastname"]}
            messages={formMessages["lastname"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, lastname: v })
            }
            disabled={disabled}
          />
        </div>

        <div className="form__control">
          <MyDateInput
            label={getText("t_birth_date")}
            value={birth_date}
            setValue={(v) => setGuest({ ...guest, birth_date: v })}
            validators={name2validators["birth_date"]}
            messages={formMessages["birth_date"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, checkout_date: v })
            }
            disabled={disabled}
          />
        </div>
        <div className="form__control">
          <MyAutocompleteInput
            label={getText("t_citizenship")}
            placeholder={getText("ph_france")}
            values={COUNTRY_ENGLISH}
            value={citizenship}
            setValue={(v) => setGuest({ ...guest, citizenship: v })}
            validators={name2validators["citizenship"]}
            messages={formMessages["citizenship"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, citizenship: v })
            }
            disabled={disabled}
          />
        </div>
        <div className="form__control">
          <MyAutocompleteInput
            label={getText("t_residency")}
            placeholder={getText("ph_france")}
            values={COUNTRY_ENGLISH}
            value={residency}
            setValue={(v) => setGuest({ ...guest, residency: v })}
            validators={name2validators["residency"]}
            messages={formMessages["residency"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, residency: v })
            }
            disabled={disabled}
          />
        </div>
        <div className="form__control">
          <MyAutocompleteInput
            label={getText("t_place_of_birth")}
            placeholder={getText("ph_france")}
            values={COUNTRY_ENGLISH}
            value={place_of_birth}
            setValue={(v) => setGuest({ ...guest, place_of_birth: v })}
            validators={name2validators["place_of_birth"]}
            messages={formMessages["place_of_birth"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, place_of_birth: v })
            }
            disabled={disabled}
          />
        </div>
        {!saved && (haveValidImages || timedOut) && (
          <div className="control_center">
            <div className="btn bg_primary" onClick={() => handleSave()}>
              {getText("btn_save_guest")}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div key={guest["id"]} className="form-container w520_ifw">
      <div
        className="card__header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3 className="guest-header">Guest #{idx + 1}</h3>
        <div
          className="btn"
          style={{
            minWidth: "auto",
            padding: "0",
            alignSelf: "center",
            justifySelf: "flex-end",
            fontSize: "14px",
          }}
          onClick={() => onDelete()}
        >
          <img
            src="/images/delete.svg"
            alt="delete"
            className="filter_gray"
            style={{ width: "1.2em" }}
          />
        </div>
      </div>
      <div className="step-container">
        <span
          className={
            "step-number " + (documentType ? "step-number_active" : "")
          }
        >
          2
        </span>

        <div className="form-card">
          <h4 className="step-header">{getText("s_document_select")}</h4>
          <div className="document-select">
            <MyRadioButtonLabeled
              label={"Identity card"}
              selected={documentType === DOCUMENT_IDENTITY_CARD}
              handleSelect={() => setDocumentType(DOCUMENT_IDENTITY_CARD)}
            />
            <MyRadioButtonLabeled
              label={"Passport"}
              selected={documentType === DOCUMENT_PASSPORT}
              handleSelect={() => setDocumentType(DOCUMENT_PASSPORT)}
            />
          </div>
          <h4>{getText("s_document_select_note")}</h4>
        </div>
      </div>

      {documentType && (
        <div className="step-container">
          <span
            className={
              "step-number " +
              (haveValidImages || timedOut ? "step-number_active" : "")
            }
          >
            3
          </span>
          <div className="form-card">
            
            {renderImages()}
          </div>
          {/* <div className="form-card">
            <div className="example-img-container">
              <img
                src={"/images/mrz_example.png"}
                alt="example"
                className="example-img"
              />
              <h4>{getText("s_take_photo_note")}</h4>
            </div>
          </div> */}
        </div>
      )}

      {(haveValidImages || timedOut) && (
        <div className="step-container">
          <span className="step-number step-number_active">4</span>
          <div className="form-card">
            <h4 className="step-header">{getText("s_correct_data")}</h4>
            {renderGuestEdit()}
          </div>
        </div>
      )}
    </div>
  );
};
