import React, { useContext, useState } from "react";

import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
} from "../../utils/FormManager";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";

const name2validators: any = {
  client_id: [notEmptyValidator()],
  roat_user: [notEmptyValidator()],
  password: [notEmptyValidator()],
};

interface IRoatCredentialsProps {
  setModalText: (msg: string) => void;
  setLoading: (x: boolean) => void;
}
export function RoatCredentials({
  setModalText,
  setLoading,
}: IRoatCredentialsProps) {
  const { requestManager, user, setUser, getText } = useContext(AppContext);

  const [client_id, setClient_id] = useState("");
  const [roat_user, setRoat_user] = useState("");
  const [password, setPassword] = useState("");

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  const composeCodeData = () => {
    const data = {
      client_id: client_id.trim(),
      roat_user: roat_user.trim(),
      password: password.trim(),
    };
    const invalidFields = validateForm(data, name2validators);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages({ ...formMessages, ...msgs });
      return null;
    }
    return data;
  };
  const handleLogin = async () => {
    setLoading(true);
    const data = composeCodeData();
    if (!data) {
      setLoading(false);
      return;
    }
    const resp = await requestManager.postJson(API_URLS.USER_ROAT_UPDATE, data);
    if (resp.status === 401) {
      setLoading(false);
      setModalText(getText("m_failed_authorize"));
      return;
    } else if (resp.status !== 200) {
      setLoading(false);
      setModalText(getText("m_failed_roat_update"));
      return;
    } else {
      setModalText(getText("m_success_roat_update"));
    }

    setLoading(false);
  };

  return (
    <div className="form-container w520_ifw">
      <div className="form-card">
        <div
          className="card__header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3 className="card__header-title">{getText("h_login")}</h3>
        </div>

        <div className="control__wrap">
          <div className="form__control control_wide">
            <MyInput
              type="text"
              label={getText("l_roat_client_id")}
              placeholder={""}
              value={client_id}
              setValue={(v) => setClient_id(v)}
              validators={name2validators["client_id"]}
              messages={formMessages["client_id"] || []}
              setMessages={(v) =>
                setFormMessages({ ...formMessages, client_id: v })
              }
            />
          </div>

          <div className="form__control control_wide">
            <MyInput
              type="text"
              label={getText("l_mil_id")}
              placeholder={""}
              value={roat_user}
              setValue={(v) => setRoat_user(v)}
              validators={name2validators["roat_user"]}
              messages={formMessages["roat_user"] || []}
              setMessages={(v) =>
                setFormMessages({ ...formMessages, roat_user: v })
              }
            />
          </div>

          <div className="form__control control_wide">
            <MyInput
              type="password"
              label={getText("l_roat_password")}
              placeholder={""}
              value={password}
              setValue={(v) => setPassword(v)}
              validators={name2validators["password"]}
              messages={formMessages["password"] || []}
              setMessages={(v) =>
                setFormMessages({ ...formMessages, password: v })
              }
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              //   flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div className="form__control">
              <div className="btn bg_secondary" onClick={() => navigate(-1)}>
                {getText("btn_back")}
              </div>
            </div>
            <div className="form__control">
              <div className="btn bg_primary" onClick={() => handleLogin()}>
                {getText("btn_save")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
