


interface IMyToggleProps {
    active: boolean;
}
export function MyToggle({active}:IMyToggleProps) {
    return (<img
        src={
            active ? "/images/triangle-up.svg" : "/images/triangle-down.svg"
        }
        alt="expand"
        style={{ width: "1.2em" }}
      />);
};