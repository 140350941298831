import React from "react";

interface IMyFileInputProps {
  label?: string;
  btn_label?: string;
  setValue: (f: any) => void;
  mimes?: string;
  clsName?: string;
}
export const MyFileInput: React.FC<IMyFileInputProps> = ({
  label,
  btn_label,
  setValue,
  mimes = "image/png, image/jpeg",
  clsName="bg_success",
}) => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    const element = e.target;
    const file = element.files.length > 0 ? element.files[0] : null;
    // console.log("file:", file);
    setValue(file);
  };
  return (
    <React.Fragment>
      <label className="control-label">{label}</label>
      <label className={"btn " + clsName}>
        <input
          type="file"
          style={{ position: "absolute", left: "-99999rem" }}
          accept={mimes}
          onChange={(e) => handleChange(e)}
        />
        {btn_label}
      </label>
    </React.Fragment>
  );
};
