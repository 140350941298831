import React, { useEffect, useRef, useState } from "react";

interface IMyDropdownProps {
  selectedIdx: number;
  forceOpen: boolean;
}
export function MyDropdown(props: React.PropsWithChildren<IMyDropdownProps>) {
  const [open, setOpen] = useState(false);
  const handleClick = (e: any) => {
    if (!open || props.forceOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    return false;
  };
  const items = props.children as any;
  return (
    <div className="dropdown" onClick={handleClick}>
      {!props.forceOpen && (
        <React.Fragment>
          {items[props.selectedIdx]}
          {(open || props.forceOpen) &&
            items.filter((o: any, i: number) => i != props.selectedIdx)}
        </React.Fragment>
      )}
      {props.forceOpen && items}
    </div>
  );
}
