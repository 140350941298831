import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
} from "../../utils/FormManager";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";
import { MyLoading, MyModal } from "../common/MyModal";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
  company_name: [],
  company_name_nrt: [],
};

const name2validatorsPassword = {
  password_old: [lengthValidator(8, 255)],
  password: [lengthValidator(8, 255)],
  password2: [lengthValidator(8, 255)],
};

export function CustomerAccount() {
  const { requestManager, user, getText } = useContext(AppContext);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_name_nrt, setCompany_name_nrt] = useState("");
  const [password_old, setPassword_old] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPhone(user.phone);
    setEmail(user.email);
  }, [user]);

  const updateMessages = (invalidFields: [string, string][]) => {
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages((formMessages) => ({ ...formMessages, ...msgs }));
    }
  };

  const composeData = () => {
    const data = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      phone: phone.trim(),
      email: email.trim(),
      company_name: company_name.trim(),
      company_name_nrt: company_name_nrt.trim(),
    };
    const invalidFields = validateForm(data, name2validators);
    updateMessages(invalidFields);

    if (invalidFields.length > 0) {
      return null;
    }

    return data;
  };

  const composeDataPassword = () => {
    const data = {
      password_old: password_old.trim(),
      password: password.trim(),
      password2: password2.trim(),
    };
    const invalidFields = validateForm(data, name2validatorsPassword);
    updateMessages(invalidFields);

    if (invalidFields.length > 0) {
      return null;
    }

    return data;
  };

  const handleSaveUserData = async () => {
    setLoading(true);
    const data = composeData();
    if (!data) {
      setModalText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_USER_DATA_SAVE,
      data
    );

    if (resp.status === 200) {
      // console.log(resp);
      setModalText(getText("m_user_data_update_succ"));
    } else {
      setModalText(getText("m_user_data_update_fail"));
    }

    setLoading(false);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const data = composeDataPassword();
    if (!data) {
      setModalText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_USER_PASSWORD_SAVE,
      data
    );

    if (resp.status === 200) {
      // console.log(resp);
      setModalText(getText("m_user_password_update_succ"));
    } else {
      setModalText(getText("m_user_password_update_fail"));
    }

    setLoading(false);
  };

  return (
    <div className="page-content">
      <div className="form-container w520_ifw">
        <div className="form-card">
          <div className="control__wrap">
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_firstname")}
                placeholder={getText("ph_firstname")}
                value={firstname}
                setValue={(v) => setFirstname(v)}
                validators={name2validators["firstname"]}
                messages={formMessages["firstname"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, firstname: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_lastname")}
                placeholder={getText("ph_lastname")}
                value={lastname}
                setValue={(v) => setLastname(v)}
                validators={name2validators["lastname"]}
                messages={formMessages["lastname"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, lastname: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="phone"
                label={getText("l_phone")}
                placeholder={getText("ph_phone")}
                value={phone}
                setValue={(v) => setPhone(v)}
                validators={name2validators["phone"]}
                messages={formMessages["phone"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, phone: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="email"
                label={getText("l_email")}
                placeholder={getText("ph_email")}
                value={email}
                setValue={(v) => setEmail(v)}
                validators={name2validators["email"]}
                messages={formMessages["email"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, email: v })
                }
              />
            </div>

            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_companyname")}
                placeholder={getText("ph_companyname")}
                value={company_name}
                setValue={(v) => setCompany_name(v)}
                validators={name2validators["company_name"]}
                messages={formMessages["company_name"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, company_name: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_companyname_nrt")}
                placeholder={getText("ph_companyname_nrt")}
                value={company_name_nrt}
                setValue={(v) => setCompany_name_nrt(v)}
                validators={name2validators["company_name_nrt"]}
                messages={formMessages["company_name_nrt"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, company_name_nrt: v })
                }
              />
            </div>
          </div>

          <div className="control_center">
            <div
              className="btn bg_primary "
              onClick={() => handleSaveUserData()}
            >
              {getText("btn_save_data")}
            </div>
          </div>
        </div>

        <div className="control__wrap">
          <div className="form-card">
            <div className="control__wrap">
              <div className="form__control">
                <MyInput
                  type="password"
                  label={getText("l_password_old")}
                  placeholder={getText("ph_password_old")}
                  value={password_old}
                  setValue={(v) => setPassword_old(v)}
                  validators={name2validatorsPassword["password_old"]}
                  messages={formMessages["password_old"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, password_old: v })
                  }
                />
              </div>
              <div className="form__control">
                <MyInput
                  type="password"
                  label={getText("l_password")}
                  placeholder={getText("ph_password")}
                  value={password}
                  setValue={(v) => setPassword(v)}
                  validators={name2validatorsPassword["password"]}
                  messages={formMessages["password"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, password: v })
                  }
                />
              </div>
              <div className="form__control">
                <MyInput
                  type="password"
                  label={getText("l_password2")}
                  placeholder={getText("ph_password")}
                  value={password2}
                  setValue={(v) => setPassword2(v)}
                  validators={name2validatorsPassword["password2"]}
                  messages={formMessages["password2"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, password2: v })
                  }
                />
                {password !== password2 && (
                  <p className="error-message">{getText("m_pass_not_match")}</p>
                )}
              </div>
            </div>
            <div className="control_center">
              <div
                className="btn bg_primary "
                onClick={() => handleChangePassword()}
              >
                {getText("btn_save_new_password")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
}
