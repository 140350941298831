import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyToggle } from "../common/MyToggle";
import { ICustomerInfo2, IHeaderInfo } from "../common/types";
import { monthDiff, sortRows } from "../../utils/helpers";

interface IReservationsPageProps {
  customerInfos: ICustomerInfo2[];
}

interface ICustomerRow {
  name: string;
  created_at: string;
  number_of_property: number;
  invoiced: number;
  xx: number;
  guest_count: number;
  reservation_count: number;
  valid: boolean;
  failed_attempts: number;
}

const HEADER_INFOS: IHeaderInfo[] = [
  { label: "Manager Name", key: "name" },
  { label: "Registered", key: "created_at" },
  { label: "Nb of prop.", key: "number_of_property" },
  { label: "Invoiced", key: "invoiced" },
  { label: "Nb Guests", key: "guest_count" },
  { label: "XX", key: "xx" },
  { label: "Active", key: "valid" },
  { label: "Cancel", key: "" },
  { label: "Failed loading", key: "failed_attempts" },
];

export const ManagersBlock: React.FC<IReservationsPageProps> = ({
  customerInfos,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);

  const [rowsRef, setRowsRef] = useState<ICustomerRow[]>([]);
  const [rows, setRows] = useState<ICustomerRow[]>([]);

  useEffect(() => {
    // console.log("ReservationsBlock prepareRows", reservationsInfo, showAllGuests, tableName);
    prepareRows(customerInfos);
  }, [customerInfos]);

  useEffect(() => {
    const rows_ = sortRows(rowsRef, sortKey, sortAsc);
    setRows(rows_);
  }, [rowsRef]);

  const handleChangeSortKey = (header: string | null) => {
    let asc = sortAsc;
    if (sortKey === header) {
      asc = !asc;
    } else {
      asc = false;
      setSortKey(header);
    }
    setSortAsc(asc);
  };

  const renderHeader = () => {
    return (
      <tr>
        {HEADER_INFOS.map((hinfo, hi) => {
          const active_ = sortKey === hinfo.key;
          return (
            <th key={hinfo.key} onClick={() => handleChangeSortKey(hinfo.key)}>
              <div style={{ display: "flex", minWidth: "160px" }}>
                {hinfo.label}&nbsp;
                <MyToggle active={active_} />
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const prepareRows = (customerInfos: ICustomerInfo2[]) => {
    const newRows = customerInfos.map((customerInfo) => {
      const date2set: any = {};
      customerInfo.ra_dates.forEach(
        ({ ra_id, checkin_date, checkout_date }) => {
          if (!checkin_date || !checkout_date) {
            return;
          }
          const d1 = new Date(checkin_date);
          const d2 = new Date(checkin_date);
          const monthCount = monthDiff(d2, d1) + 1;
          for (let i = 0; i < monthCount; i++) {
            const d = new Date(d1.getFullYear(), d1.getMonth() + i);
            const dStr = d.getFullYear() + "-" + (d.getMonth() + 1); //d.toLocaleDateString("en-US");

            if (!(dStr in date2set)) {
              date2set[dStr] = new Set();
            }
            date2set[dStr].add(ra_id);
          }
        }
      );
      let invoiced: number = 0;
      let xx:number = 0;
      Object.keys(date2set).forEach((date: any) => {
        const count = date2set[date].size;
        invoiced += 9.88 * count;
        xx += count;
      });

      const row: ICustomerRow = {
        name: [customerInfo.lastname, customerInfo.firstname].join(" "),
        created_at: customerInfo.created_at,
        number_of_property: customerInfo.number_of_property,
        invoiced,
        xx,
        guest_count: customerInfo.guest_count,
        reservation_count: customerInfo.reservation_count,
        valid: customerInfo.valid,
        failed_attempts:
          customerInfo.extraction_attempts_sum -
          customerInfo.extraction_success_sum,
      };

      return row;
    });

    setRowsRef(newRows);
    return newRows;
  };

  const renderRowsManagers = () => {
    return rows.map((row) => {
      return (
        <tr className="reservations-table__row">
          <td>
            <div className="reservations-table__row__cell">{row.name}</div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.created_at}
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.number_of_property}
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.invoiced?.toFixed(2)}
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.guest_count}
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              {row.reservation_count}
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div className="btn filled" onClick={() => {}}>
                {row.valid ? "Yes" : "No"}
              </div>
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div
                className={row.valid ? "btn outline" : "btn filled"}
                onClick={() => {}}
              >
                {row.valid ? "Cancel" : "Canceled"}
              </div>
            </div>
          </td>
          <td>
            <div className="reservations-table__row__cell">
              <div className="btn filled_green">{row.failed_attempts}</div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableManagers = () => {
    return (
      <table className="reservations-table combined_1_to_6">
        <thead>{renderHeader()}</thead>
        <tbody>{renderRowsManagers()}</tbody>
      </table>
    );
  };

  return <div>{renderTableManagers()}</div>;
};
