import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { notEmptyValidator } from "../../utils/FormManager";
import { MyInput } from "../common/MyInput";

const name2validators = {
  email: [notEmptyValidator()],
};

export function AppDownload() {
  const { getText } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  const handleNotify = () => {};

  return (
    <div className="page-content">
      <div style={{ display: "flex", flexWrap: "wrap", paddingTop: "20px" }}>
        <div className="form-card" style={{ width: "100%" }}>
          <h3 style={{ padding: "8px 0", marginBottom: "32px", borderBottom: "1px solid black" }}>
            {getText("h_app_download")}
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              paddingLeft: "32px",
            }}
          >
            <div>We are sorry, we are working on it.</div>
            <div>
              Our App resource is not yet available, apologies for any
              inconveniences.
            </div>
            <div>
              If you want to be alerted when the App is ready, leave your email
              below,
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h3 style={{padding: "20px 0 0 0", margin: "0"}}>{getText("l_email")}</h3>
                <div style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "flex-end",
              }}>
                  <div className="form__control" style={{marginTop: "0"}}>
                    <MyInput
                      type="email"
                      label={""}
                      placeholder={getText("ph_email")}
                      value={email}
                      setValue={(v) => setEmail(v)}
                      validators={name2validators["email"]}
                      messages={formMessages["email"] || []}
                      setMessages={(v) =>
                        setFormMessages({ ...formMessages, email: v })
                      }
                    />
                  </div>
                  <div className="form__control">
                    <div
                      className="btn bg_primary "
                      onClick={() => handleNotify()}
                    >
                      {getText("btn_alert_me")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
