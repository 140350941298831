import React, { useContext } from "react";
import { AppContext } from "../../utils/AppContext";

interface IPackage {
    header: string;
    price: string;
    content: string;
};
const packages:IPackage[] = [
  {
    header: `BASIC`,
    price: "7,99€",
    content: `per month per property
        your tourists do their own check-in, you get an email with confirmation and the data transmitted to the government server, as well as the identifications used that you need to store, by law, during 12 months`,
  },
  {
    header: "BRONZE",
    price: "9,99€",
    content: `per month per property with tourists data storage
same as Basic offer but we store your tourists identification and information for you, by law, during 12 months",`,
  },
  {
    header: `SILVER`,
    price: "11,99€",
    content: `per month per property with storage and IEAT collection and payment
same as Bronze offer, but we collect IEAT for you and we pay it to you or to the government at the end of each quarter`,
  },
  {
    header: `GOLD`,
    price: `13,99€`,
    content: `per month per property with storage, IEAT and deposit collection and repayment
same as Silver offer, but we collect deposit for you before the stay starts and we pay it back to the tourists or to you 2 weeks after the end date of the stay `,
  },
  {
    header: `PREMIUM`,
    price: "15,99€",
    content: `per month per property with storage, IEAT, deposit and synchronisation to the channels
    same as Gold, but with the synchronisation to the channel to get alerts when check-in not done, real earnings, IEAT visibility, check-in error prevention`,
  },
];

export function TermsAndPrices() {
  const { getText } = useContext(AppContext);

  return (
    <div className="page-content">
      <div style={{ display: "flex", flexWrap: "wrap", gap: "24px", paddingTop: "24px" }}>
        {packages.map((pkg: IPackage) => {
            return (<div className="form-card price-card">
                <h3 className="content-card-header">{pkg.header}</h3>
                <h3 className="content-card-header text-primary">{pkg.price}</h3>
                <div className="content-list">
                  <div>
                    <div>
                      {pkg.content}
                    </div>
                  </div>
      
                  <div className="control_right">
                    <div className="btn bg_success" onClick={() => {}}>
                      {getText("btn_buy_package")}
                    </div>
                  </div>
                </div>
              </div>);
        })}
        
      </div>
    </div>
  );
}
