import React from "react";

interface IMyDateInpuyProps {
  value: any;
  setValue: (x: any) => void;
  validators?: any[];
  messages?: string[];
  setMessages?: (ms: string[]) => void;
  label?: string;
  style_?: any;
  readOnly?: boolean;
  disabled?: boolean;
}
export const MyDateInput: React.FC<IMyDateInpuyProps> = ({
  label = "",
  value = "",
  setValue,
  validators = [],
  messages = [],
  setMessages = () => {},
  style_ = {},
  readOnly = false,
  disabled=false,
}) => {
  // console.log("MyDateInput: value: ", value);
  const handleChange = (v: any) => {
    // console.log(v);
    if (validators) {
      const msgs: string[] = [];
      validators.forEach((validator) => {
        const res = validator(v);
        if (!res.status) {
          msgs.push(res.message);
        }
      });
      if (messages.length === 0 && msgs.length === 0) {
      } else {
        setMessages(msgs);
      }
    }
    setValue(v);
  };

  return (
    <React.Fragment>
      <div className="control-label">{label}</div>
      <input
        className="input-date"
        type="date"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        style={style_}
        readOnly={readOnly}
        disabled={disabled}
      />
      {messages.length > 0 &&
        messages.map((m, i) => (
          <p key={i} className="error-message">
            {m}
          </p>
        ))}
    </React.Fragment>
  );
};
