import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
} from "../../utils/FormManager";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
  password: [lengthValidator(8, 255)],
  password2: [lengthValidator(8, 255)],
};

const name2validatorsCode = {
  code: [notEmptyValidator()],
};

interface ICustomerRegisterProps {
  setModalText: (msg: string) => void;
  setLoading: (x: boolean) => void;
}

export function CustomerRegister({
  setModalText,
  setLoading,
}: ICustomerRegisterProps) {
  const { requestManager, setUser, getText } = useContext(AppContext);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [code, setCode] = useState("");

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();

  const composeCodeData = () => {
    const data = {
      email: email.trim(),
      password: password.trim(),
      password2: password2.trim(),
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      phone: phone.trim(),
    };
    const invalidFields = validateForm(data, name2validators);

    if (invalidFields.length > 0 || password !== password2) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages({ ...formMessages, ...msgs });
      return null;
    }
    return data;
  };
  const handleRegister = async () => {
    setLoading(true);
    const data = composeCodeData();
    if (!data) {
      setModalText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const res = await requestManager.register(
      data.email,
      data.password,
      data.firstname,
      data.lastname,
      data.phone
    );

    setLoading(false);
    if (res.status === 200) {
      setUser(res.data);
      handleRedirectToVerify();
    } else if (res.status === 409) {
      setShowConfirm(true);
      setModalText(getText("m_customer_exists_not_vefified"));
    } else if (res.status === 403) {
      setModalText(getText("m_customer_exists"));
      return;
    } else if (res.status === 422) {
      setModalText(getText("m_some_fields_invalid"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    const data = { code: code };
    if (!data) {
      setLoading(false);
      setModalText(getText("m_some_fields_invalid"));
      return;
    }
    const res = await requestManager.postJson(
      API_URLS.USER_CODE_VERIFY,
      data,
      false
    );
    setLoading(false);
    if (res.status === 200) {
      // navigate(WEB_ROUTERS.CUSTOMER_LOGIN_OR_REGISTER);
      setModalText(getText("m_try_login"));
    } else if (res.status === 404) {
      setModalText(getText("m_code_invalid"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const handleRedirectToVerify = () => {
    // navigate(WEB_ROUTERS.CUSTOMER_REGISTER_VERIFY);
  };


  const handleRegisterUserBatch = () => {
    navigate(WEB_ROUTERS.CUSTOMER_REGISTER_USER_BATCH);
  };

  return (
    <div  className="form-container w520_ifw">
      <div className="form__control" style={{alignSelf: "flex-start"}}>
        <div
          className="btn bg_primary"
          onClick={() => handleRegisterUserBatch()}
        >
          {getText("btn_register_tourist_batch")}
        </div>
      </div>

      <div className="form-container  w520_ifw">
        <div className="form-card">
          <div
            className="card__header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3 className="card__header-title">{getText("h_register")}</h3>
          </div>

          <div className="control__wrap">
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_firstname")}
                placeholder={getText("ph_firstname")}
                value={firstname}
                setValue={(v) => setFirstname(v)}
                validators={name2validators["firstname"]}
                messages={formMessages["firstname"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, firstname: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_lastname")}
                placeholder={getText("ph_lastname")}
                value={lastname}
                setValue={(v) => setLastname(v)}
                validators={name2validators["lastname"]}
                messages={formMessages["lastname"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, lastname: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="phone"
                label={getText("l_phone")}
                placeholder={getText("ph_phone")}
                value={phone}
                setValue={(v) => setPhone(v)}
                validators={name2validators["phone"]}
                messages={formMessages["phone"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, phone: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="email"
                label={getText("l_email")}
                placeholder={getText("ph_email")}
                value={email}
                setValue={(v) => setEmail(v)}
                validators={name2validators["email"]}
                messages={formMessages["email"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, email: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="password"
                label={getText("l_password")}
                placeholder={getText("ph_password")}
                value={password}
                setValue={(v) => setPassword(v)}
                validators={name2validators["password"]}
                messages={formMessages["password"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, password: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="password"
                label={getText("l_password2")}
                placeholder={getText("ph_password")}
                value={password2}
                setValue={(v) => setPassword2(v)}
                validators={name2validators["password2"]}
                messages={formMessages["password2"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, password2: v })
                }
              />
              {password !== password2 && (
                <p className="error-message">{getText("m_pass_not_match")}</p>
              )}
            </div>
          </div>
          <div className="control_center">
            <div className="btn bg_primary " onClick={() => handleRegister()}>
              {getText("btn_register")}
            </div>
          </div>
        </div>
      </div>

      <div className="form-card">
        <div className="control__wrap" style={{ alignItems: "center" }}>
          <div className="form__control">
            <MyInput
              type="text"
              label={getText("l_code")}
              placeholder={getText("ph_code")}
              value={code}
              setValue={(v) => setCode(v)}
              validators={name2validatorsCode["code"]}
              messages={formMessages["code"] || []}
              setMessages={(v) => setFormMessages({ ...formMessages, code: v })}
            />
          </div>
          <div className="form__control">{getText("msg_code_sent")}</div>
        </div>
        <div className="control_center">
          <div className="btn bg_primary" onClick={() => handleVerify()}>
            {getText("btn_start_checkin")}
          </div>
        </div>
      </div>
    </div>
  );
}
