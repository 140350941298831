import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";

import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { MySelect } from "../common/MySelect";
import { MyDateInput } from "../common/MyDateInput";
import { MyInput } from "../common/MyInput";
import {
  ICustomerInfo2,
  IReservation,
  IReservationsFilterOptions,
} from "../common/types";
import { WEB_ROUTERS } from "../../utils/routes";
import { ReservationsBlock } from "../common/ReservationsBlock";
import { ManagersBlock } from "../common/ManagersBlock";

interface IReservationsPageProps {
  tableName: string;
}

export const AdminReservationsPage: React.FC<IReservationsPageProps> = ({
  tableName,
}) => {
  const { getText, user, requestManager } = useContext(AppContext);

  const [filterOptions, setFilterOptions] =
    useState<IReservationsFilterOptions>({
      reservationId: -1,
      from: "",
      to: "",
      guestName: "",
    });

  const [showAllGuests, setShowAllGuests] = useState<boolean>(true);

  const [managerInfos, setManagerInfos] = useState<ICustomerInfo2[]>([]);
  const [selectedManagerId, setSelectedManagerId] = useState<number>(-1);
  const [reservationsInfo, setReservationsInfo] = useState<IReservation[]>([]);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const inProgress = useRef(false);

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  useEffect(() => {
    refreshManagerInfos();
  }, []);

  useEffect(() => {
    // console.log("refresh");
    if (selectedManagerId && selectedManagerId !== -1) {
      refreshReservationsInfo();
    } else {
      setReservationsInfo([]);
    }
  }, [managerInfos, selectedManagerId]);

  const refreshManagerInfos = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await loadManagerInfos();
    inProgress.current = false;
  };

  const loadManagerInfos = async () => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_MANAGER_INFOS_GETALL
    );
    if (resp.status === 200) {
      const managers_ = resp.data;
      setManagerInfos(managers_);
      if (managers_.length > 0) {
        setSelectedManagerId(managers_[0].id);
      }
      setLoading(false);
      return managers_;
    } else {
      setModalText("Failed to load manager infos");
    }
    setLoading(false);
    return [];
  };

  const refreshReservationsInfo = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await loadReservationsInfo();
    inProgress.current = false;
  };

  const loadReservationsInfo = async () => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.ADMIN_V2_RESERVATION_INFO_GETALL2,
      { user_id: selectedManagerId }
    );
    if (resp.status === 200) {
      const reservations_ = resp.data;
      setReservationsInfo(reservations_);
      setLoading(false);
      return reservations_;
    } else {
      setModalText("Failed to load reservation info");
    }
    setLoading(false);
    return [];
  };

  const handleReservationCancel = async (
    reservation_application_id: number,
    canceled_at: string | null
  ) => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_APPLICATIONS_SAVE,
      { id: reservation_application_id, canceled_at }
    );
    if (resp.status === 200) {
      await refreshReservationsInfo();
      setLoading(false);
      return;
    } else {
      setModalText("Failed to change cancel");
    }
    setLoading(false);
  };

  const handleReservationDeposit = async (
    reservation_application_id: number,
    deposit_amount: number
  ) => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_APPLICATIONS_SAVE,
      { id: reservation_application_id, deposit_amount }
    );
    if (resp.status === 200) {
      await refreshReservationsInfo();
      setLoading(false);
      return;
    } else {
      setModalText("Failed to change keep");
    }
    setLoading(false);
  };

  const managerSelectionOptions = managerInfos.map((minfo, mi) => {
    return { value: minfo.id, label: minfo.email };
  });
  const reservationSelectOptions = reservationsInfo.map((rinfo, ri) => {
    return { value: rinfo.id, label: rinfo.address };
  });

  return (
    <div className="page-content">
      <div className="form-card" style={{ overflow: "auto" }}>
        <div className="reservation-filters">
          <MySelect
            options={[{ value: -1, label: "None" }, ...managerSelectionOptions]}
            value={selectedManagerId}
            onChange={(v: any) => setSelectedManagerId(+v)}
          />
          <MySelect
            options={[{ value: -1, label: "All" }, ...reservationSelectOptions]}
            value={filterOptions.reservationId}
            onChange={(v: any) =>
              setFilterOptions({ ...filterOptions, reservationId: +v })
            }
          />
          <MyDateInput
            value={filterOptions.from}
            setValue={(v) => setFilterOptions({ ...filterOptions, from: v })}
          />
          <MyDateInput
            value={filterOptions.to}
            setValue={(v) => setFilterOptions({ ...filterOptions, to: v })}
          />
          <MyInput
            type="text"
            label={""}
            value={filterOptions.guestName}
            setValue={(v) =>
              setFilterOptions({ ...filterOptions, guestName: v })
            }
            placeholder="Search Guest Name"
          />
          <div
            className={showAllGuests ? "btn filled" : "btn outline"}
            onClick={() => setShowAllGuests((v) => !v)}
          >
            {getText("btn_guests")}
          </div>
        </div>
        {tableName === "managers" && (
          <ManagersBlock customerInfos={managerInfos} />
        )}
        {tableName !== "managers" && (
          <ReservationsBlock
            reservationsInfo={reservationsInfo}
            tableName={tableName}
            onCancel={handleReservationCancel}
            onDeposit={handleReservationDeposit}
            filterOptions={filterOptions}
            showAllGuests={showAllGuests}
          />
        )}
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
};
