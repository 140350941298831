import React from "react";

interface IMyInpuyProps {
  type: string;
  label: string;
  placeholder?: string;
  value: any;
  setValue: (val: any) => void;
  validators?: ((v: any) => any)[];
  messages?: string[];
  setMessages?: (ms: string[]) => void;
  disabled?: boolean;
}
export const MyInput: React.FC<IMyInpuyProps> = ({
  type,
  label,
  placeholder = "",
  value,
  setValue,
  validators = [],
  messages=[],
  setMessages=()=>{},
  disabled=false,
}) => {
  const handleChange = (v: any) => {
    if (validators) {
      const msgs: string[] = [];
      validators.forEach((validator) => {
        const res = validator(v);
        if (!res.status) {
          msgs.push(res.message);
        }
      });
      // console.log("setMessages", msgs);
      setMessages(msgs);
    }
    setValue(v);
  };
  return (
    <React.Fragment>
      <label className="control-label">{label}</label>
      <input
        className="input_outline"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
      />
      {messages.length > 0 &&
        messages.map((m, i) => (
          <p key={i} className="error-message">
            {m}
          </p>
        ))}
    </React.Fragment>
  );
};
