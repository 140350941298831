import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";

const STORAGE_SERVER = "server";
const STORAGE_CUSTOMER = "customer";
const PAYMENT_METHOD_CREDIT_CARD = "credit_card";

interface IAccomodationSubscribeProps {
  idx: number;
  accomodation_id: number;
  // accomodation: any;
  onStorageSelect: (value: string) => void;
  onPaymentMethodSelect: (value: string) => void;
}

export function AccomodationSubscribe({
  idx,
  accomodation_id,
  // accomodation,
  onStorageSelect,
  onPaymentMethodSelect,
}: IAccomodationSubscribeProps) {
  const { getText } = useContext(AppContext);
  const [isStorageSelect, setIsStorageSelect] = useState(true);

  const handleStorageSelect = (value: string) => {
    setIsStorageSelect(false);
    onStorageSelect(value);
  };
  const handlePaymentMethodSelect = (value: string) => {
    setIsStorageSelect(true);
    onPaymentMethodSelect(value);
  };

  const renderStorageSelect = () => {
    return (
      <React.Fragment>
        <div className="form__control">{getText("t_subscribe_property")}</div>

        <div className="form__control ">
          <div
            className="btn bg_primary btn_small"
            onClick={() => handleStorageSelect(STORAGE_CUSTOMER)}
          >
            {getText("btn_store_customer")}
          </div>
          <p>{getText("t_store_customer")}</p>
        </div>
        <div className="form__control">
          <div
            className="btn bg_primary btn_small"
            onClick={() => handleStorageSelect(STORAGE_SERVER)}
          >
            {getText("btn_store_server")}
          </div>
          <p>{getText("t_store_server")}</p>
        </div>
        <div className="form__control">
          {getText("t_subscribe_description")}
        </div>
      </React.Fragment>
    );
  };
  const renderPaymentMethodSelect = () => {
    return (
      <React.Fragment>
          <div className="form__control control_center">
            <h3>{getText("t_select_payment_method")}</h3>
            <img src={"/images/payment2.png"}  style={{width: "200px", alignSelf: "center"}} />
          </div>
          
          <div className="form__control control_center">
            <div
              className="btn bg_success"
              onClick={() =>
                handlePaymentMethodSelect(PAYMENT_METHOD_CREDIT_CARD)
              }
            >
              {getText("btn_credit_card")}
            </div>
          </div>
      </React.Fragment>
    );
  };

  return (
    <div key={accomodation_id} className="form-container w520_ifw">
      <div className="form-card">
        <div
          className="card__header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {(idx >= 0) && <h3>{getText("h_subscribe_property") + (idx + 1)}</h3>}
        </div>
        <div className="control__wrap text_center min_w480">
          {isStorageSelect && renderStorageSelect()}
          {!isStorageSelect && renderPaymentMethodSelect()}
        </div>
        <div className="header-tag">{getText("t_subscribe_note_1")}</div>
        <div className="header-tag">{getText("t_subscribe_note_2")}</div>
      </div>
    </div>
  );
}
