import React from "react";

export function MyLoading() {
  return (
    <React.Fragment>
      <div className="absoluteFill">
        <div className="loading-spinner"></div>
      </div>
    </React.Fragment>
  );
}

export function MyModal(props: any) {
    return (
      <React.Fragment>
        <div className="absoluteFill">
          <div className="modal-base">
            {props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
  