import React, { useState } from "react";

interface IMyAutocompleteInputProps {
  type?: string;
  label: string;
  placeholder?: string;
  values: string[];
  value: string;
  setValue: (v: string) => void;
  validators: any[];
  messages: string[];
  setMessages: (ms: string[]) => void;
  disabled?: boolean;
}
export const MyAutocompleteInput: React.FC<IMyAutocompleteInputProps> = ({
  type="text",
  label,
  placeholder="",
  values,
  value,
  setValue,
  validators = [],
  messages,
  setMessages,
  disabled=false,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const handleChange = (v: string) => {
    if (validators) {
      const msgs: string[] = [];
      validators.forEach((validator) => {
        const res = validator(v);
        if (!res.status) {
          msgs.push(res.message);
        }
      });
      // console.log("setMessages", msgs);
      setMessages(msgs);
    }
    setValue(v);
    if (v.length > 0) {
      let suggestions_ = values.filter((val) =>
        val.toLowerCase().startsWith(v.toLowerCase())
      );
      // console.log(v,suggestions_);
      if (suggestions_.length == 1 && suggestions_[0] == v) {
        suggestions_ = [];
      }
      setSuggestions(suggestions_);
    } else {
      setSuggestions([]);
    }
  };
  return (
    <React.Fragment>
      <div className="control-label">{label}</div>
      <input
        className="input_outline"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
      />
      {suggestions.length > 0 && (
        <div className="suggestions-box">
          {suggestions.map((s, i) => (
            <p
              onClick={() => {
                setSuggestions([]);
                handleChange(s);
              }}
              style={{cursor: "pointer"}}
            >
              {s}
            </p>
          ))}
        </div>
      )}

      {messages.length > 0 &&
        messages.map((m, i) => (
          <p key={i} className="error-message">
            {m}
          </p>
        ))}
    </React.Fragment>
  );
};
