import React from "react";

interface IMySelectProps {
  options: { value: string | number; label: string | number }[];
  value: string | number;
  onChange: (v: string) => void;
  cls?: string
}
export function MySelect(props: IMySelectProps) {
  return (
    <select
      value={props.value}
      className={"input_outline "+(props.cls??"")}
      onChange={(e) => props.onChange(e.target.value)}
    >
      {props.options.map((o, i) => (
        <option key={o.value} value={o.value}>{o.label}</option>
      ))}
    </select>
  );
}
