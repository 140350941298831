import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";

import { MyLoading, MyModal } from "../common/MyModal";
import { WEB_ROUTERS } from "../../utils/routes";
import { CustomerLogin } from "./Login";

export const LoginPage: React.FC<{}> = () => {
  const { getText } = useContext(AppContext);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLinkRegister = () => {
    navigate(WEB_ROUTERS.CUSTOMER_REGISTER);
  };

  return (
    <div className="page-content">
      {/* <h3 className="header-tag">{getText("h_login_register")}</h3> */}
      <div className="header-tag">
        <h3 className="header-tag__title">
          {getText("h_login_register2")}
        </h3>
        <h3 className="header-tag__title">{getText("h_login_register3")}</h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "40px",
          paddingTop: "20px",
        }}
      >
        <CustomerLogin setModalText={setModalText} setLoading={setLoading} />
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
};
