import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyToggle } from "../common/MyToggle";
import { MySelect } from "../common/MySelect";
import { MyInput } from "../common/MyInput";
import { ICustomerInfo, IHeaderInfo } from "../common/types";
import { makeDeepCopy, sortRows } from "../../utils/helpers";
import { MyRadioButton } from "../common/MyButton";
import { MyDateInput } from "../common/MyDateInput";

interface ICustomerTableProps {
  datas: ICustomerInfo[];
  saveDatas: (customers: ICustomerInfo[]) => void;
  selected: ICustomerInfo | null;
  onSelect: (customer: ICustomerInfo) => void;
  onDelete: () => void;
}

const headerInfos: IHeaderInfo[] = [
  { label: "Edit", key: "" },
  { label: "Client code", key: "customer_code" },
  { label: "Last name", key: "lastname" },
  { label: "First name", key: "firstname" },
  { label: "Mobile Nb", key: "phone" },
  { label: "Email", key: "email" },
  { label: "Date register", key: "created_at" },
  { label: "Nb of property", key: "number_of_property" },
  { label: "Nb of check-in", key: "number_of_checkin" },
  { label: "Data storage", key: "storage" },
  { label: "Date storage", key: "storage_at" },
  { label: "Fee applied", key: "fee_applied" },
  { label: "Total Invoiced", key: "total" },
  { label: "Visualize", key: "" },
];

export const CustomerTable: React.FC<ICustomerTableProps> = ({
  datas,
  saveDatas,
  selected,
  onSelect,
  onDelete,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [rowsEdit, setRowsEdit] = useState<ICustomerInfo[]>([]);
  const [rowsSorted, setRowsSorted] = useState<ICustomerInfo[]>([]);

  useEffect(() => {
    setRowsEdit(makeDeepCopy(datas));
  }, [datas]);

  useEffect(() => {
    const rows = sortRows(rowsEdit, sortKey, sortAsc);
    setRowsSorted(rows);
  }, [rowsEdit, sortKey, sortAsc]);

  const handleChangeSortKey = (header: string | null) => {
    let asc = sortAsc;
    if (sortKey === header) {
      asc = !asc;
    } else {
      asc = false;
      setSortKey(header);
    }
    setSortAsc(asc);
  };

  const renderHeader = () => {
    return (
      <tr>
        {headerInfos.map((hinfo, hi) => {
          const active_ = sortKey === hinfo.key;
          return (
            <th key={hi} onClick={() => handleChangeSortKey(hinfo.key)}>
              <div style={{ display: "flex", minWidth: "160px" }}>
                {hinfo.label}&nbsp;
                <MyToggle active={active_} />
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const renderRows = () => {
    return rowsSorted.map((row) => {
      return (
        <tr key={row.id}>
          <td>
            <div>
              <MyRadioButton checked={false} />
            </div>
          </td>
          <td>
            <div>{row.customer_code}</div>
          </td>
          <td>
            <div>{row.lastname}</div>
          </td>
          <td>
            <div>{row.firstname}</div>
          </td>
          <td>
            <div>{row.phone}</div>
          </td>
          <td>
            <div>{row.email}</div>
          </td>
          <td>
            <div>{row.created_at}</div>
          </td>
          <td>
            <div>{row.number_of_property}</div>
          </td>
          <td>
            <div>{row.number_of_checkin}</div>
          </td>
          <td>
            <div>{row.storage}</div>
          </td>
          <td>
            <div>{row.storage_at}</div>
          </td>
          <td>
            <div>1.99</div>
          </td>
          <td>
            <div>0</div>
          </td>
          <td onClick={() => onSelect(row)}>
            <MyRadioButton checked={selected?.id === row.id} />
          </td>
        </tr>
      );
    });
  };

  const updateCustomer = (newValue: ICustomerInfo) => {
    setRowsEdit((rows) =>
      rows.map((row) => (row.id !== newValue.id ? row : newValue))
    );
  };

  const renderRowsEdit = () => {
    return rowsSorted.map((row) => {
      return (
        <tr key={row.id}>
          <td></td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.customer_code}
                setValue={(v) =>
                  updateCustomer({
                    ...row,
                    customer_code: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.lastname}
                setValue={(v) =>
                  updateCustomer({
                    ...row,
                    lastname: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.firstname}
                setValue={(v) =>
                  updateCustomer({
                    ...row,
                    firstname: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.phone}
                setValue={(v) =>
                  updateCustomer({
                    ...row,
                    phone: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyInput
                type="text"
                label={""}
                value={row.email}
                setValue={(v) =>
                  updateCustomer({
                    ...row,
                    email: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>{row.created_at}</div>
          </td>
          <td>
            <div>{row.number_of_property}</div>
          </td>
          <td>
            <div>{row.number_of_checkin}</div>
          </td>
          <td>
            <div>
              <MySelect
                options={[
                  { value: "server", label: "server" },
                  { value: "customer", label: "customer" },
                ]}
                value={row.storage}
                onChange={(v: any) =>
                  updateCustomer({
                    ...row,
                    storage: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>
              <MyDateInput
                value={row.storage_at}
                setValue={(v) =>
                  updateCustomer({
                    ...row,
                    storage_at: v,
                  })
                }
              />
            </div>
          </td>
          <td>
            <div>1.99</div>
          </td>
          <td>
            <div>0</div>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  const checkModified = () => {
    return JSON.stringify(datas) !== JSON.stringify(rowsEdit);
  };

  const modified = checkModified();

  return (
    <div>
      <div style={{ display: "flex", justifyContent:"flex-end", gap: "32px" }}>
        <div className="btn btn_bg" onClick={() => setEditMode(true)}>
          <img
            src="/images/edit.svg"
            className="filter_gray"
            style={{ width: "1.5em" }}
            alt="edit"
          />
        </div>
        <div className="btn btn-with-image" onClick={() => onDelete()}>
          <img
            src="/images/delete2.svg"
            className="filter_gray"
            style={{ width: "1.2em" }}
            alt="delete2"
          />
        </div>
      </div>
      <div className="form-card card_scroll">
        <table>
          <thead>{renderHeader()}</thead>
          <tbody>{editMode ? renderRowsEdit() : renderRows()}</tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent:"flex-end", gap: "32px" }}>
        {editMode && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div
              className="btn bg_secondary"
              onClick={() => setEditMode(false)}
            >
              Stop Editing
            </div>
          </div>
        )}
        {modified && (
          <div className="form_control" style={{ padding: "20px" }}>
            <div
              className="btn bg_primary"
              onClick={() => saveDatas(rowsEdit)}
            >
              Save
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
