import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { MyInput } from "../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
} from "../../utils/FormManager";
import { MyLoading, MyModal } from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";

const name2validatorsGenerate = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
};

const name2validatorsCode = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
  code: [notEmptyValidator()],
};

const name2validatorsPassword = {
  email: [notEmptyValidator()],
  code: [notEmptyValidator()],
  password: [lengthValidator(8, 255)],
  password2: [lengthValidator(8, 255)],
};

interface IPasswordResetProps {}

export function PasswordReset({}: IPasswordResetProps) {
  const { requestManager, user, setUser, getText } = useContext(AppContext);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [code, setCode] = useState("");

  const [verified, setVerified] = useState(false);

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();

  const composeCodeData = (
    data: { [key: string]: any },
    n2v: { [key: string]: any }
  ) => {
    const invalidFields = validateForm(data, n2v);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages({ ...formMessages, ...msgs });
      return null;
    }
    return data;
  };

  const handleGenerateCode = async () => {
    setLoading(true);
    setVerified(false);
    const data_ = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      phone: phone.trim(),
    };
    const data = composeCodeData(data_, name2validatorsGenerate);
    if (!data) {
      setModalText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const res = await requestManager.postJson(
      API_URLS.USER_PASSWORD_RESETCODE,
      data,
      false
    );
    setLoading(false);
    if (res.status == 200) {
      setModalText(getText("m_code_sent"));
    } else {
      setModalText(getText("m_failed_generate_code"));
    }
  };

  const handleValidate = async () => {
    setLoading(true);
    const data_ = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      phone: phone.trim(),
      code: code.trim(),
    };
    const data = composeCodeData(data_, name2validatorsCode);
    if (!data) {
      setModalText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const res = await requestManager.postJson(
      API_URLS.USER_PASSWORD_CHECKCODE,
      data,
      false
    );
    setLoading(false);
    if (res.status == 200) {
      setVerified(true);
    } else {
      setVerified(false);
      setModalText(getText("m_code_invalid"));
    }
  };

  const handleSavePassword = async () => {
    setLoading(true);
    const data_ = {
      email: email.trim(),
      password: password.trim(),
      password2: password2.trim(),
      code: code.trim(),
    };
    const data = composeCodeData(data_, name2validatorsPassword);
    if (!data) {
      setModalText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    delete data["password2"];
    const res = await requestManager.postJson(
      API_URLS.USER_PASSWORD_RESET,
      data,
      false
    );
    setLoading(false);
    if (res.status == 200) {
      // Alert.alert("Debug", JSON.stringify(res));
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    } else {
      // Alert.alert("Debug", JSON.stringify(res));
      setModalText(getText("m_failed_change_pass"));
      setVerified(false);
    }
  };

  return (
    <div className="page-content">
      <div className="form-container">
        <div className="form-card">
          <div className="control__wrap">
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_firstname")}
                placeholder={getText("ph_firstname")}
                value={firstname}
                setValue={(v) => setFirstname(v)}
                validators={name2validatorsCode["firstname"]}
                messages={formMessages["firstname"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, firstname: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("t_lastname")}
                placeholder={getText("ph_lastname")}
                value={lastname}
                setValue={(v) => setLastname(v)}
                validators={name2validatorsCode["lastname"]}
                messages={formMessages["lastname"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, lastname: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="phone"
                label={getText("l_phone")}
                placeholder={getText("ph_phone")}
                value={phone}
                setValue={(v) => setPhone(v)}
                validators={name2validatorsCode["phone"]}
                messages={formMessages["phone"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, phone: v })
                }
              />
            </div>
            <div className="form__control">
              <MyInput
                type="email"
                label={getText("l_email")}
                placeholder={getText("ph_email")}
                value={email}
                setValue={(v) => setEmail(v)}
                validators={name2validatorsCode["email"]}
                messages={formMessages["email"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, email: v })
                }
              />
            </div>
          </div>
          <div className="control_center">
            <div
              className="btn bg_primary"
              onClick={() => handleGenerateCode()}
            >
              {getText("btn_generate_code")}
            </div>
          </div>
        </div>

        <div className="form-card">
          <div className="control__wrap" style={{ alignItems: "center" }}>
            <div className="form__control">
              <MyInput
                type="text"
                label={getText("l_code")}
                placeholder={getText("ph_code")}
                value={code}
                setValue={(v) => setCode(v)}
                validators={name2validatorsCode["code"]}
                messages={formMessages["code"] || []}
                setMessages={(v) =>
                  setFormMessages({ ...formMessages, code: v })
                }
              />
            </div>
            <div className="form__control">{getText("msg_code_sent")}</div>
          </div>
          <div className="control_center">
            <div className="btn bg_primary" onClick={() => handleValidate()}>
              {getText("btn_verify_code")}
            </div>
          </div>
        </div>

        {verified && (
          <div className="form-card">
            <div className="control__wrap" style={{ alignItems: "center" }}>
              <div className="form__control">
                <MyInput
                  type="password"
                  label={getText("l_password")}
                  placeholder={getText("ph_password")}
                  value={password}
                  setValue={(v) => setPassword(v)}
                  validators={name2validatorsPassword["password"]}
                  messages={formMessages["password"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, password: v })
                  }
                />
              </div>
              <div className="form__control">
                <MyInput
                  type="password"
                  label={getText("l_password2")}
                  placeholder={getText("ph_password")}
                  value={password2}
                  setValue={(v) => setPassword2(v)}
                  validators={name2validatorsPassword["password2"]}
                  messages={formMessages["password2"] || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, password2: v })
                  }
                />
                {password != password2 && (
                  <p className="error-message">{getText("m_pass_not_match")}</p>
                )}
              </div>
            </div>
            <div className="control_center">
              <div
                className="btn bg_primary"
                onClick={() => handleSavePassword()}
              >
                {getText("btn_change_password")}
              </div>
            </div>
          </div>
        )}
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
}
