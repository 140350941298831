import React from 'react';
import { InvoiceData, CalculationResult } from '../types';
import { FileText } from 'lucide-react';

interface Props {
  invoiceData: InvoiceData;
  calculation: CalculationResult;
  onProceedToPayment: () => void;
}

export function InvoicePreview({ invoiceData, calculation, onProceedToPayment }: Props) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-8 max-w-2xl mx-auto">
      <div className="flex justify-between items-start mb-8">
        <div>
          <h2 className="text-2xl font-bold text-gray-800">IEAT Tourist Tax Invoice</h2>
          <p className="text-gray-600">Invoice #{invoiceData.id}</p>
        </div>
        <FileText className="w-8 h-8 text-blue-600" />
      </div>

      <div className="grid grid-cols-2 gap-8 mb-8">
        <div>
          <h3 className="font-semibold text-gray-700 mb-2">Bill To:</h3>
          <p className="text-gray-600">{invoiceData.customerName}</p>
          <p className="text-gray-600">{invoiceData.email}</p>
          <p className="text-gray-600 whitespace-pre-line">{invoiceData.address}</p>
          {invoiceData.vatNumber && (
            <p className="text-gray-600">VAT: {invoiceData.vatNumber}</p>
          )}
        </div>
        <div>
          <h3 className="font-semibold text-gray-700 mb-2">Stay Details:</h3>
          <p className="text-gray-600">Check-in: {invoiceData.checkIn}</p>
          <p className="text-gray-600">Check-out: {invoiceData.checkOut}</p>
          <p className="text-gray-600">Number of Adults: {invoiceData.adults}</p>
          <p className="text-gray-600">Number of Nights: {invoiceData.nights}</p>
        </div>
      </div>

      <div className="border-t border-gray-200 pt-4 mb-8">
        <div className="flex justify-between mb-2">
          <span className="text-gray-600">Tax Rate per Person per Night</span>
          <span className="font-medium">€{calculation.taxRate.toFixed(2)}</span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="text-gray-600">Subtotal</span>
          <span className="font-medium">€{calculation.subtotal.toFixed(2)}</span>
        </div>
        <div className="flex justify-between text-lg font-bold">
          <span>Total</span>
          <span>€{calculation.total.toFixed(2)}</span>
        </div>
      </div>

      <button
        onClick={onProceedToPayment}
        className="w-full bg-green-600 text-white py-3 px-4 rounded-md hover:bg-green-700 transition-colors"
      >
        Proceed to Payment
      </button>

      <p className="text-center text-sm text-gray-500 mt-4">
        This is an official IEAT tourist tax invoice for Andorra
      </p>
    </div>
  );
}