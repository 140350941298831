import React from "react";

export const MyCloseButton = ({ onClick }: any) => {
  return (
    <img
      src="/images/close.png"
      style={{ width: "2em", alignSelf: "flex-end" }}
      onClick={onClick}
      alt="close"
    />
  );
};
export function MyButton(props: any) {
  return (
    <div
      className={props.btnType + " btn"}
      style={props.btnStyle || {}}
      onClick={() => props.handleClick()}
    >
      {props.children}
    </div>
  );
}
export function MyRadioButton({
  checked,
  handleClick = () => {},
  styleContainer = {},
  styleStick = {},
}: any) {
  const stickClass = checked ? "" : "invisible";
  return (
    <div
      style={styleContainer}
      className={"btn-radio"}
      onClick={() => handleClick()}
    >
      <div
        style={styleStick}
        className={stickClass + " btn-radio__stick"}
      ></div>
    </div>
  );
}
export function MyRadioButtonLabeled({ label, selected, handleSelect }: any) {
  return (
    <div
      className="card_outline"
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "0.2em 0.5em",
        alignItems: "center",
        gap: "0.5rem",
      }}
      onClick={handleSelect}
    >
      <MyRadioButton checked={selected} />
      <p style={{ flexGrow: 1 }}>{label}</p>
    </div>
  );
}
