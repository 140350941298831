import React, { useContext, useRef } from "react";
import { AppContext } from "../../utils/AppContext";

export function Guide() {
  const { getText } = useContext(AppContext);
  const videosRef = useRef<HTMLDivElement>(null);

  // const showVideos = () => {
  //   videosRef.current?.scrollIntoView();
  // };

  return (
    <div className="page-content">
      <h3
        className="header-tag header_big tx_gray off_small tx_med"
        style={{ fontSize: "16px" }}
      >
        {getText("h_guide")}
      </h3>
      <h3
        className="header-tag off_small tx_med"
        style={{ fontSize: "16px", paddingTop: 0 }}
      >
        {getText("t_guide")}
      </h3>
      <div
        ref={videosRef}
        style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
      >
        <div className="form-card">
          <h3>{getText("h_video_tourist")}</h3>
          <div className="control__wrap">
            <div className="video-container video-container_small">
              {/* <iframe
                width="420px"
                height="250px"
                src="https://www.youtube.com/embed/AGu60-bamq0?si=nLo4llaq0xV8NqNT"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              <iframe
                // width="420px"
                // height="250px"
                src="https://www.youtube.com/embed/mgY6Klox8Oc?si=0tcAHyGDcyrhzAS4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="form-card">
          <h3>{getText("h_video_customer_register")}</h3>
          <div className="control__wrap">
            <div className="video-container video-container_small">
              <iframe
                // width="420px"
                // height="250px"
                src="https://www.youtube.com/embed/AGu60-bamq0?si=nLo4llaq0xV8NqNT"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="form-card">
          <h3>{getText("h_video_customer_property")}</h3>
          <div className="control__wrap">
            <div className="video-container video-container_small">
              <iframe
                // width="420px"
                // height="250px"
                src="https://www.youtube.com/embed/AGu60-bamq0?si=nLo4llaq0xV8NqNT"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
